import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import axios from 'axios'
import { mergeEntityPage } from '@app/lib/utils'

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export const fetchNotices: any = createAsyncThunk('notices/fetchNotices', async (params: { first: number, after: string }) => {
  const response: any = await axios.get('/api/auth/notices', {params: {
    first: params.first,
    last: null,
    after: params.after,
    before: null
  }})
  return response?.data?.response
})

interface IUserNoticesACF {
  acfNotices: {
    notices?: {
      edges: any[]
      pageInfo: any
    }
  }
  status: Status // Loading status for this data structure
  error: string | null  // A place to keep any error status for the fetch that attempts to fill this data structure
}

const initialState: IUserNoticesACF = {
  acfNotices: {},
  status: Status.IDLE,
  error: null
}

export const notices = createSlice({
  name: 'notices',
  initialState: initialState,
  reducers: {
    resetNotices(state) {
      state.acfNotices = {}
      state.status = Status.IDLE
    },
  },
  extraReducers: {
    [fetchNotices.pending.toString()]: (state) => {
      state.status = Status.LOADING

    },
    [fetchNotices.fulfilled.toString()]: (state, action) => {
      state.status = Status.SUCCEEDED
      if (state.acfNotices?.notices && action.payload?.notices) {
        state.acfNotices.notices = mergeEntityPage(state.acfNotices.notices, action.payload.notices)
        state.acfNotices.notices.pageInfo = action.payload.notices.pageInfo
      } else {
        state.acfNotices = action.payload
      }
    },
    [fetchNotices.rejected.toString()]: (state, action) => {
      state.status = Status.FAILED
      state.error = action.error.message

      console.log('error occurred. message: ')
      console.log(action.error.message)
    },
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const {
  resetNotices,
} = notices.actions

export default notices.reducer
