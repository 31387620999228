import { useUser } from './useUser'
import { useEffect, useMemo, useState } from 'react'
import type { Router } from 'next/router'

export const useHubspot = (router: Router) => {
  const { session } = useUser()
  const [email, setEmail] = useState<string | undefined>()
  const uri = useMemo(() => router.asPath, [router.asPath])
  const hsq: unknown[] | undefined = useMemo(() => {
    if (typeof window === 'undefined') {
      return
    }

    if (!Array.isArray((window as any)._hsq)) {
      (window as any)._hsq = []
    }

    return (window as any)._hsq
  }, [])

  useEffect(() => {
    if (session && session.isLoggedIn && session.data.email) {
      setEmail(session.data.email)
    }
  }, [session])

  useEffect(() => {
    if (email && hsq) {
      hsq.push(['identify', { email }])
    }
  }, [email, hsq])

  useEffect(() => {
    if (hsq) {
      hsq.push(['setPath', uri])
      hsq.push(['trackPageView'])
    }
  }, [uri, hsq])
}