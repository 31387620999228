//-----------------
// This is the Redux slice in which is stored a user's set of employers(locations), which in turn contain their Pay and Conditions (P&C), which in turn contain their EBA's.
// This is shortly to be refactored
//-----------------

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import axios from 'axios'
import { AppState } from '..'

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export const fetchUserDateOfBirth = createAsyncThunk('employers/fetchUserDateOfBirth',
  async () => {
    const response = await axios.get('/api/auth/userinfo')
    return response.data?.me?.dateOfBirth
  }
)

export const fetchEmployers = createAsyncThunk('employers/fetchEmployers',
  async () => {
    const response = await axios.get('/api/auth/employers')
    return response.data?.employers
  })

export const fetchPayAndConditions = createAsyncThunk('employers/fetchPayAndConditions', async () => {
  const response = await axios.get('/api/auth/payAndConditions')
  return response.data
})

export const pushEmploymentStatusUpdate = createAsyncThunk('employers/pushEmploymentStatusUpdate', async (emp: any, { getState }) => {
  const state: AppState = getState()

  const newLevel = emp?.newLevel
  const newEmploymentStatus = emp?.newEmploymentStatus
  const currentEmployerId = emp?.currentEmployerId ?? state.employers?.currentEmployerId

  const response = await axios.post('/api/auth/updateEmployment', {
    newLevel: newLevel,
    newEmploymentStatus: newEmploymentStatus,
    currentEmployerId: currentEmployerId,
  })

  return response.data
})

export const pushClearEmploymentData = createAsyncThunk('employers/pushClearEmploymentData', async (_arg: void, { getState, dispatch }) => {
  const state: AppState = getState()

    const response = await axios.post('/api/auth/updateEmployment',
      {
        newLevel: null,
        newEmploymentStatus: null,
        currentEmployerId: state.employers?.currentEmployerId,
      })

    dispatch(setPayRateIndicatorState('request-reset'))

    return response.data
  })

export interface IDownloadAwardSection {
  awardImage: string
  bodyText: string
  fullEbaUrl: string
  downloadTitle: string
  currentFrom: string
}

export interface IAwardIndicator {
  awardImage?: string
  title: string
  subtitle: string
  bodyText: string
  buttonText: string
  buttonUrl: string
  isInAlternatePosition?: boolean
}

export interface IPayRate {
  description?: string,
  name: string,
  base: string,
  after6monfri: string,
  sat: string,
  sun?: string
}

export interface IEmploymentTypeAge {
  ageName: string,
  data: IPayRate
}

export interface IEmploymentTypeLevel {
  levelName: string,
  data: IPayRate
}
export interface IEmploymentType {
  typeName: string,
  levels: IEmploymentTypeLevel[]
  ages: IEmploymentTypeAge[]
}

export interface IWorkplaceInfoIndicator {
  title?: string,
  employer?: string,
  location?: string,
  employmentStatus?: string,
  level?: string,
}

export interface IEmployer {
  id: string
  workplaceInfo: IWorkplaceInfoIndicator
  name: string
  payAndConditionsId: string
}

export interface IAwardAccordion {
  title: string
  bodyHtml: string
}

interface IEmployersState {
  payRateData: any
  downloadAwardSection?: IDownloadAwardSection
  awardIndicator?: IAwardIndicator
  employers: IEmployer[]
  status: Status
  payAndConditionsLoadState: Status
  error: string | null
  payRateIndicatorState: 'prefill-attempt' | 'settled' | 'request-reset' | 'request-switch-employer'
  currentEmployerId: string | null
  awardAccordion?: IAwardAccordion[]
  dateOfBirth: string | null
  employStatusSelected: Record<string, string>
  retailValueSelected: Record<string, string>
}

export const initialState: IEmployersState = {
  currentEmployerId: null,
  error: null,
  status: Status.IDLE,
  payAndConditionsLoadState: Status.IDLE,
  payRateIndicatorState: 'prefill-attempt',
  employers: [],
  employStatusSelected: {},
  retailValueSelected: {},
  payRateData: {},
  dateOfBirth: ''
}

function getArrayIndexOfEmployerWithId(id: string | null, employers: any) {
  if (!Array.isArray(employers) || employers.length < 1 || !id) {
    return 0
  }

  let employerIndex = 0

  for (let i = 0; i < employers.length; i++) {
    if (employers[i].id === id) {
      employerIndex = i
    }
  }

  return employerIndex
}

export const employers = createSlice({
  name: 'employers',
  initialState: initialState,
  reducers: {
    viewEmployer(state, action) {
      state.currentEmployerId = action.payload
    },
    setPayRateIndicatorState(state, action) {
      state.payRateIndicatorState = action.payload
    },
    setEmployerId(state, action) {
      state.currentEmployerId = action.payload
    },
    clearEmploymentLevel(state) {
      const employerIndex = getArrayIndexOfEmployerWithId(state.currentEmployerId, state.employers)

      state.employers[employerIndex].workplaceInfo.level = undefined
      state.employers[employerIndex].workplaceInfo.employmentStatus = undefined
    },
    setEmploymentLevel(state, action) {
      const employerIndex = getArrayIndexOfEmployerWithId(state.currentEmployerId, state.employers)

      state.employers[employerIndex].workplaceInfo.level = action.payload.newLevel
      state.employers[employerIndex].workplaceInfo.employmentStatus = action.payload.newEmploymentStatus
    },
    setEmployStatusSelected(state, action) {
      if (state.currentEmployerId) {
        // TODO: implement real store migrations
        if (typeof state.employStatusSelected === 'string') state.employStatusSelected = {}
        state.employStatusSelected[state.currentEmployerId] = action.payload
      }
    },
    setRetailValueSelected(state, action) {
      if (state.currentEmployerId) {
        // TODO: implement real store migrations
        if (typeof state.retailValueSelected === 'string') state.retailValueSelected = {}
        state.retailValueSelected[state.currentEmployerId] = action.payload
      }
    }
  },
  extraReducers: {
    [fetchUserDateOfBirth.fulfilled.toString()]: (state, action) => {
      state.dateOfBirth = action.payload
    },
    [fetchEmployers.pending.toString()]: (state) => {
      state.status = Status.LOADING
    },
    [fetchEmployers.fulfilled.toString()]: (state, action) => {
      state.status = Status.SUCCEEDED
      state.employers = action.payload
    },
    [fetchEmployers.rejected.toString()]: (state, action) => {
      state.status = Status.FAILED
      state.error = action.error.message
    },
    [fetchPayAndConditions.pending.toString()]: (state) => {
      state.payAndConditionsLoadState = Status.LOADING
    },
    [fetchPayAndConditions.fulfilled.toString()]: (state, action) => {
      state.payAndConditionsLoadState = Status.SUCCEEDED

      state.payRateData = action?.payload?.payAndConditions
    },
    [fetchPayAndConditions.rejected.toString()]: (state, action) => {
      state.payAndConditionsLoadState = Status.FAILED
      state.error = action.error.message
    },
    [pushClearEmploymentData.pending.toString()]: (state) => {
      state.status = Status.LOADING
    },
    [pushClearEmploymentData.fulfilled.toString()]: (state) => {
      state.status = Status.SUCCEEDED
    },
    [pushClearEmploymentData.rejected.toString()]: (state, action) => {
      state.status = Status.FAILED
      state.error = action.error.message
    },
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const {
  viewEmployer,
  setPayRateIndicatorState,
  clearEmploymentLevel,
  setEmployerId,
  setEmploymentLevel,
  setEmployStatusSelected,
  setRetailValueSelected,
} = employers.actions

export default employers.reducer
