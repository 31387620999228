// this file is a wrapper with defaults to be used in both API routes and `getServerSideProps` functions
import { GetServerSidePropsContext, GetServerSidePropsResult, NextApiHandler } from 'next'
import { withIronSessionApiRoute, withIronSessionSsr } from 'iron-session/next'

// TODO: this name is required for use with Firebase/Firestore
export const cookieName = '__session'
declare module 'iron-session' {
  interface IronSessionData {
    user?: Record<any, any>
  }
}

const sessionOptions = {
  password: process.env.SECRET_COOKIE_PASSWORD ?? '',
  cookieName,
  cookieOptions: {
    // the next line allows to use the session in non-https environments like
    // Next.js dev mode (http://localhost:3000)
    httpOnly: false,
    secure: process.env.NEXT_PUBLIC_ENV === 'development' ? false : true,
  },
}

export function withSessionRoute(handler: NextApiHandler) {
  return withIronSessionApiRoute(handler, sessionOptions)
}

export function withSessionSSR<
  P extends { [key: string]: unknown } = { [key: string]: unknown },
  >(
    handler: (
      context: GetServerSidePropsContext,
    ) => GetServerSidePropsResult<P> | Promise<GetServerSidePropsResult<P>>,
) {
  return withIronSessionSsr(handler, sessionOptions)
}
