import { createSlice, createAsyncThunk, Dispatch } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import axios from 'axios'
import { AppState } from '..'
import { mergeEntityPage } from '@app/lib/utils'

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export const fetchNews: any = createAsyncThunk(
  'news/fetchNews',
  async (params: { first: number, after: string }) => {
    const response: any = await axios.get('/api/auth/news', {
      params: {
        first: params.first,
        last: null,
        after: params.after,
        before: null
      },
      _retry: 5,
    })
    return response?.data?.response
  })

interface IUserNewsACF {
  acfNews: {
    newsArticles?: {
      edges: any[]
      pageInfo: any
    }
  }
  status: Status // Loading status for this data structure
  error: string | null  // A place to keep any error status for the fetch that attempts to fill this data structure
}

const initialState: IUserNewsACF = {
  acfNews: {},
  status: Status.IDLE,
  error: null
}

export const news = createSlice({
  name: 'news',
  initialState: initialState,
  reducers: {
    resetNews(state) {
      state.acfNews = {}
      state.status = Status.IDLE
    },
  },
  extraReducers: {
    [fetchNews.pending.toString()]: (state) => {
      state.status = Status.LOADING

    },
    [fetchNews.fulfilled.toString()]: (state, action) => {
      state.status = Status.SUCCEEDED
      if (state.acfNews?.newsArticles && action.payload?.newsArticles) {
        state.acfNews.newsArticles = mergeEntityPage(state.acfNews.newsArticles, action.payload.newsArticles)
        state.acfNews.newsArticles.pageInfo = action.payload.newsArticles.pageInfo
      } else {
        state.acfNews = action.payload
      }
    },
    [fetchNews.rejected.toString()]: (state, action) => {
      state.status = Status.FAILED
      state.error = action.error.message

      console.log('error occurred. message: ')
      console.log(action.error.message)
    },
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const switchNewsBranch = (callback: any) => async (dispatch: Dispatch, getState: AppState) => {
  const { news: { status } } = getState()

  if (status === Status.LOADING) return

  dispatch(resetNews())
  dispatch(callback)
}

export const {
  resetNews,
} = news.actions

export default news.reducer
