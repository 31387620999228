import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import axios from 'axios'

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export const fetchForms: any = createAsyncThunk('forms/fetchForms', async (params: { formId: number }) => {
  const response: any = await axios.get('/api/auth/forms', {params: { formId: params.formId }})
  return response?.data?.response
})

export const fetchResponseStatus: any = createAsyncThunk('forms/fetchResponseStatus', async (params: { formId: number }) => {
  const response: any = await axios.get('/api/auth/formResponseStatus', {params: { formId: params.formId }})
  return response?.data
})

interface IformSJS {
	branch: string
  form_id: number
  json: {
    pages: [{
      elements: []
    }]
  }
}

interface IUserFormsSJS {
  sjsForms: Record<string, IformSJS>
  sjsResponseStatus: boolean
  status: Status // Loading status for this data structure
  error: string | null  // A place to keep any error status for the fetch that attempts to fill this data structure
}

export const initialState: IUserFormsSJS = {
  sjsForms: {},
  sjsResponseStatus: true,
  status: Status.IDLE,
  error: null
}


export const forms = createSlice({
  name: 'forms',
  initialState: initialState,
  reducers: {
  },
  extraReducers: {
    [fetchForms.pending.toString()]: (state) => {
      state.status = Status.LOADING

    },
    [fetchForms.fulfilled.toString()]: (state, action) => {
      state.status = Status.SUCCEEDED
      state.sjsForms[action.payload.form_id] = action.payload
    },
    [fetchForms.rejected.toString()]: (state, action) => {
      state.status = Status.FAILED
      state.error = action.error.message

      console.log('error occurred. message: ')
      console.log(action.error.message)
    },
    [fetchResponseStatus.pending.toString()]: (state) => {
      state.status = Status.LOADING

    },
    [fetchResponseStatus.fulfilled.toString()]: (state, action) => {
      state.status = Status.SUCCEEDED
      state.sjsResponseStatus = action.payload
    },
    [fetchResponseStatus.rejected.toString()]: (state, action) => {
      state.status = Status.FAILED
      state.error = action.error.message

      console.log('error occurred. message: ')
      console.log(action.error.message)
    },
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export default forms.reducer
