import useSWR from 'swr'
import axios from 'axios'
import { useRouter } from 'next/router'

export interface UserSession {
  session: {
    isLoggedIn: boolean
    data: any
  }
}

export type UseUserHookArgs = {
  revalidateOnMount?: boolean
  revalidateOnFocus?: boolean
  revalidateOnReconnect?: boolean
  revalidate?: boolean
}

const fetcher = (url: string) => axios.get(url).then(res => res.data)

const defaultSessionSettings = {
  revalidateOnMount: true,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
  focusThrottleInterval: 5000,
}

export const useUser = (args?: UseUserHookArgs) => {
  const {
    data: session,
    error,
    mutate: mutateUser,
  } = useSWR(
    '/api/auth/user',
    fetcher,
    {
      ...defaultSessionSettings,
      revalidateIfStale: args?.revalidate ?? false,
    }
  )

  const router = useRouter()
  let branches = []

  if (session?.data) {
    session.data.branch = session.data['https://sda.com.au/branch']
    session.data.access = session.data['https://sda.com.au/access']
    branches = session.data['https://sda.com.au/locations'] && Object.keys(session.data['https://sda.com.au/locations'])

    let branchName
    switch (session.data.branch) {
      case 'SA':
        branchName = 'South Australia'
        break
      case 'QLD':
        branchName = 'Queensland'
        break
      case 'TAS':
        branchName = 'Tasmania'
        break
      case 'WA':
        branchName = 'Western Australia'
        break
      case 'Newcastle':
        branchName = 'Newcastle'
        break
      case 'VIC':
        branchName = 'VIC'
        break
      case 'NSW':
        branchName = 'NSW'
        break
    }

    const specialBranches = ['VIC', 'NSW', 'testing']

    if (
      (branchName && specialBranches.includes(branchName)) ||
      branches.find((value: string) => specialBranches.includes(value))
    ) {
      const queryString = `?token=${session?.data?.accessToken}& env=${process.env['NEXT_PUBLIC_ENV']}`

      // FIXME: due to the special nature of NEXT_PUBLIC env vars,
      // redirect urls have to be referenced directly. Ternary operator
      // or dynamic variables names WILL NOT WORK.
      if (
        (branchName && ['VIC', 'testing'].includes(branchName)) ||
        (branches.includes('VIC') || branches.includes('testing'))
      ) {
        router.push(`${process.env['NEXT_PUBLIC_VIC_REDIRECT']}${queryString}`)
      }
      if (branchName === 'NSW') {
        router.push(`${process.env['NEXT_PUBLIC_NSW_REDIRECT']}${queryString}`)
      }
    }
  }

  return {
    session,
    isValidating: !session && !error,
    mutateUser,
  }
}

