import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import axios from 'axios'
import { mergeEntityPage } from '@app/lib/utils'

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export const fetchCampaigns: any = createAsyncThunk('campaigns/fetchCampaigns', async (params: { first: number, after: string }) => {
  const response: any = await axios.get(
    '/api/auth/campaigns', {
    params: {
      first: params.first,
      last: null,
      after: params.after,
      before: null
    },
  })
  return response?.data?.response
})

interface IUserCampaignsACF {
  acfCampaigns: {
    campaigns?: {
      edges: any[]
      pageInfo: any
    }
  }
  status: Status // Loading status for this data structure
  error: string | null  // A place to keep any error status for the fetch that attempts to fill this data structure
}

const initialState: IUserCampaignsACF = {
  acfCampaigns: {},
  status: Status.IDLE,
  error: null
}

export const campaigns = createSlice({
  name: 'campaigns',
  initialState: initialState,
  reducers: {
    resetCampaigns(state) {
      state.acfCampaigns = {}
      state.status = Status.IDLE
    },
  },
  extraReducers: {
    [fetchCampaigns.pending.toString()]: (state) => {
      state.status = Status.LOADING

    },
    [fetchCampaigns.fulfilled.toString()]: (state, action) => {
      state.status = Status.SUCCEEDED
      if (state.acfCampaigns?.campaigns && action.payload?.campaigns) {
        state.acfCampaigns.campaigns = mergeEntityPage(state.acfCampaigns.campaigns, action.payload.campaigns)
        state.acfCampaigns.campaigns.pageInfo = action.payload.campaigns.pageInfo
      } else {
        state.acfCampaigns = action.payload
      }
    },
    [fetchCampaigns.rejected.toString()]: (state, action) => {
      state.status = Status.FAILED
      state.error = action.error.message

      console.log('error occurred. message: ')
      console.log(action.error.message)
    },
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const {
  resetCampaigns,
} = campaigns.actions

export default campaigns.reducer
