import { WordPressLocationEnum } from '@app/lib/graphql/wordpressClient'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { HYDRATE } from 'next-redux-wrapper'

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

axiosRetry(axios, { retries: 3 })

export const fetchMenu: any = createAsyncThunk(
  'app/fetchMenu',
  async (
    params: {
      preview: boolean,
      previewToken?: string,
      memberLocation?: string,
    },
  ) => {
    const response: any = await axios.get('/api/auth/menu', {
      params: {
        location: WordPressLocationEnum.PRIMARY_NAVIGATION,
        preview: params.preview,
        previewToken: params.previewToken,
        memberLocation: params.memberLocation,
      }
    })
    return response?.data
  })

export const fetchMobileMenu: any = createAsyncThunk(
  'app/fetchMobileMenu',
  async (
    params: {
      preview: boolean,
      previewToken?: string,
      memberLocation?: string,
    },
  ) => {
    const response: any = await axios.get('/api/auth/menu', {
      params: {
        location: WordPressLocationEnum.MOBILE_NAVIGATION,
        preview: params.preview,
        previewToken: params.previewToken,
        memberLocation: params.memberLocation,
      }
    })
    return response?.data
  })

export const fetchBranchSettings: any = createAsyncThunk(
  'app/fetchBranchSettings',
  async (
    params: {
      preview: boolean,
      previewToken?: string
    },
  ) => {
    const response: any = await axios.get('/api/auth/branchSettings', {
      params: {
        preview: params.preview,
        previewToken: params.previewToken,
      }
    })
    return response?.data
  })

export const fetchSiteSettings: any = createAsyncThunk(
  'app/fetchSiteSettings',
  async () => {
    const response: any = await axios.get('/api/auth/siteSettings')
    return response?.data
  })

interface AppState {
  mainMenuLoadingStatus: Status
  mobileMenuLoadingStatus: Status
  branchSettingsLoadingStatus: Status
  siteSettingsLoadingStatus: Status
  openMenuKey: number | null
  mobileNavMenuIsOpened: boolean
  dirty: boolean
  wantLogout: boolean
  upstreamIsDown: boolean
  absoluteMenuIndex: number
  primaryMenu: any[]
  mobileMenu: any[]
  menuLUT: any[]
  branchSettings: any[]
  siteSettings: any[]
  error: string | null
}

export const initialState: AppState = {
  mainMenuLoadingStatus: 'idle',
  mobileMenuLoadingStatus: 'idle',
  branchSettingsLoadingStatus: 'idle',
  siteSettingsLoadingStatus: 'idle',
  openMenuKey: null,
  mobileNavMenuIsOpened: false,
  dirty: false,
  wantLogout: false,
  upstreamIsDown: false,
  absoluteMenuIndex: -1,
  primaryMenu: [],
  mobileMenu: [],
  menuLUT: [],
  branchSettings: [],
  siteSettings: [],
  error: null
} as AppState

export const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setOpenMenuKey(state, action) {
      state.openMenuKey = action.payload
    },
    openMobileNavMenu(state) {
      state.mobileNavMenuIsOpened = true
    },
    closeMobileNavMenu(state) {
      state.mobileNavMenuIsOpened = false
    },
    markSessionDirty(state) {
      state.dirty = true
    },
    markSessionClean(state) {
      state.dirty = false
    },
    markUpstreamDown(state) {
      state.upstreamIsDown = true
    },
    markUpstreamRestored(state) {
      state.upstreamIsDown = false
    },
    markWantLogout(state, action) {
      state.wantLogout = action.payload
    },
    setAbsoluteMenuIndex(state, action) {
      state.absoluteMenuIndex = action.payload
    },
    setMenuLUT(state, action) {
      state.menuLUT = action.payload
    },
  },
  extraReducers: {
    [fetchMenu.pending.toString()]: (state) => {
      state.mainMenuLoadingStatus = Status.LOADING
    },
    [fetchMenu.fulfilled.toString()]: (state, action) => {
      state.mainMenuLoadingStatus = Status.SUCCEEDED
      state.primaryMenu = action.payload.menu
    },
    [fetchMenu.rejected.toString()]: (state, action) => {
      state.mainMenuLoadingStatus = Status.FAILED
      state.error = action.error.message

      console.log('error occurred. message: ')
      console.log(action.error.message)
    },
    [fetchMobileMenu.pending.toString()]: (state) => {
      state.mainMenuLoadingStatus = Status.LOADING
    },
    [fetchMobileMenu.fulfilled.toString()]: (state, action) => {
      state.mainMenuLoadingStatus = Status.SUCCEEDED
      state.mobileMenu = action.payload.menu
    },
    [fetchMobileMenu.rejected.toString()]: (state, action) => {
      state.mainMenuLoadingStatus = Status.FAILED
      state.error = action.error.message

      console.log('error occurred. message: ')
      console.log(action.error.message)
    },
    [fetchBranchSettings.pending.toString()]: (state) => {
      state.branchSettingsLoadingStatus = Status.LOADING
    },
    [fetchBranchSettings.fulfilled.toString()]: (state, action) => {
      state.branchSettingsLoadingStatus = Status.SUCCEEDED
      state.branchSettings = action.payload
    },
    [fetchBranchSettings.rejected.toString()]: (state, action) => {
      state.branchSettingsLoadingStatus = Status.FAILED
      state.error = action.error.message

      console.log('error occurred. message: ')
      console.log(action.error.message)
    },
    [fetchSiteSettings.pending.toString()]: (state) => {
      state.siteSettingsLoadingStatus = Status.LOADING
    },
    [fetchSiteSettings.fulfilled.toString()]: (state, action) => {
      state.siteSettingsLoadingStatus = Status.SUCCEEDED
      state.siteSettings = action.payload
    },
    [fetchSiteSettings.rejected.toString()]: (state, action) => {
      state.siteSettingsLoadingStatus = Status.FAILED
      state.error = action.error.message

      console.log('error occurred. message: ')
      console.log(action.error.message)
    },
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const {
  setOpenMenuKey,
  openMobileNavMenu,
  closeMobileNavMenu,
  markSessionDirty,
  markSessionClean,
  markUpstreamDown,
  markUpstreamRestored,
  markWantLogout,
  setAbsoluteMenuIndex,
  setMenuLUT,
} = appSlice.actions

export default appSlice.reducer
