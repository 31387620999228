import { useRef, useState, useEffect, SyntheticEvent, useCallback } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import { useHeartbeats } from '@app/lib/hooks/useHeartbeats'
import { useUser } from '@app/lib/hooks/useUser'
import pxToRem from '@app/ui/utils/pxToRem'
import { StyledButton as Button } from '../ButtonLink'
import { useLogout } from '@app/lib/hooks/useLogout'
import { useDispatch } from 'react-redux'
import { markUpstreamDown, markUpstreamRestored, markWantLogout } from '@app/store/slices/app-slice'
import { useAppSelector } from '@app/store'
import { useRouter } from 'next/router'

import * as Sentry from '@sentry/nextjs'

const TIMEOUT = 10

const Overlay = styled.div<{
  $timerIsExpired?: boolean
}>`
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: ${props => props.$timerIsExpired ? '1' : '0'};
  pointer-events: ${props => props.$timerIsExpired ? 'all' : 'none'};
  position: fixed;
  top: 0;
  transition: 0.4s opacity ease-in, 0.4s backdrop-filter ease-in;
  width: 100vw;
  z-index: 100;
  backdrop-filter: blur(${pxToRem(5)});
`

const Modal = styled.div<{
  $width?: string
  $backgroundColor?: string
  $mobileFullHeight?: boolean
}>`
  align-items: center;
  background: ${props => props.$backgroundColor ? props.$backgroundColor : '#F3F3F3'};
  width: 100%;
  height: ${props => (props.$mobileFullHeight && '100%') || 'auto'};
  border-radius: ${pxToRem(8)};
  padding: ${pxToRem(16)} ${pxToRem(15)} ${pxToRem(30)};
  position: relative;
  display: flex;
  flex-direction: column;
  ${media.desktop`
    padding: ${pxToRem(16)} ${pxToRem(22.5)} ${pxToRem(30)};
    width: ${(props: any) => props.$width};
    height: auto;
  `}
  filter: none;
  justify-content: space-evenly;
`

export const HeartbeatsNotifier = () => {
  const [hydrated, setHydrated] = useState(false)
  const {
    dwIsDown,
    cmsIsDown,
  } = useHeartbeats()
  const { session } = useUser()
  const dispatch = useDispatch()
  const router = useRouter()
  const performLogout = useLogout()
  const { upstreamIsDown: upstreamDisconnectUnspecified } = useAppSelector(state => state.app)
  const handleLogout = () => performLogout()
  .then(() => {
    setTimerIsExpired(false)
    router.replace('/login')
  })
  .catch((error: any) => {
    Sentry.captureException(error)
  })

  const [timerIsExpired, setTimerIsExpired] = useState<boolean>(false)
  const timer = useRef<any>(null)

  const handleButtonClick = (e: SyntheticEvent) => {
    e.preventDefault()
    handleLogout()
  }

  const toggleModalState = useCallback((state: boolean) => {
    setTimerIsExpired(state)
    dispatch(markWantLogout(state))
    if (state !== upstreamDisconnectUnspecified) {
      dispatch(state ? markUpstreamDown() : markUpstreamRestored())
    }
    // TODO: investigate the soundness of this
    // store.__persistor.persist()
  }, [dispatch, /*store.__persistor, */ upstreamDisconnectUnspecified])

  useEffect(() => {
    setHydrated(true)

    return () => {
      setHydrated(false)
    }
  }, [])

  useEffect(() => {
    if (session?.isLoggedIn) {
      if (upstreamDisconnectUnspecified) {
        toggleModalState(true)
      }

      if (dwIsDown || cmsIsDown) {
        timer.current = setTimeout(() => {
          toggleModalState(true)
        }, TIMEOUT * 1000)
      }
    }

    if (!session?.isLoggedIn || !(dwIsDown || cmsIsDown)) {
      clearTimeout(timer.current)
      toggleModalState(false)
    }

    return () => {
      clearTimeout(timer.current)
    }
  },
  [
    dwIsDown,
    cmsIsDown,
    session?.isLoggedIn,
    upstreamDisconnectUnspecified,
    toggleModalState,
  ])

  useEffect(() => {
    if (session?.isLoggedIn) {
      toggleModalState(upstreamDisconnectUnspecified)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const modal = (
    <Overlay $timerIsExpired={timerIsExpired}>
      <Modal $width={'40%'}>
        <p>
          The SDA members area is currently unavailable, please try again later
        </p>
        <br/>
        <Button
          href="/login"
          onClick={handleButtonClick}
          style={{ color: '#FFF' }}
        >
          Return to login
        </Button>
      </Modal>
    </Overlay>
  )

  return hydrated ? ReactDOM.createPortal(modal, document.getElementById('modal-root')!) : null
}
