import { createSlice, createAsyncThunk, Dispatch } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import axios from 'axios'
import { AppState } from '..'
import { mergeEntityPage } from '@app/lib/utils'

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export const fetchDiscounts: any = createAsyncThunk(
  'discounts/fetchDiscounts',
  async (params: { first: number, after: string }) => {
    const response: any = await axios.get('/api/auth/discounts', {
      params: {
        first: params.first,
        last: null,
        after: params.after,
        before: null
      },
      _retry: 5,
    })
    return response?.data?.response
  })

interface IUserDiscountsACF {
  acfDiscounts: {
    discounts?: {
      edges: any[]
      pageInfo: any
    }
  }
  status: Status // Loading status for this data structure
  error: string | null  // A place to keep any error status for the fetch that attempts to fill this data structure
}

const initialState: IUserDiscountsACF = {
  acfDiscounts: {},
  status: Status.IDLE,
  error: null
}

export const discounts = createSlice({
  name: 'discounts',
  initialState: initialState,
  reducers: {
    resetDiscounts(state) {
      state.acfDiscounts = {}
      state.status = Status.IDLE
    },
  },
  extraReducers: {
    [fetchDiscounts.pending.toString()]: (state) => {
      state.status = Status.LOADING
    },
    [fetchDiscounts.fulfilled.toString()]: (state, action) => {
      state.status = Status.SUCCEEDED
      if (state.acfDiscounts?.discounts && action.payload?.discounts) {
        state.acfDiscounts.discounts = mergeEntityPage(state.acfDiscounts.discounts, action.payload.discounts)
        state.acfDiscounts.discounts.pageInfo = action.payload.discounts.pageInfo
      } else {
        state.acfDiscounts = action.payload
      }
    },
    [fetchDiscounts.rejected.toString()]: (state, action) => {
      state.status = Status.FAILED
      state.error = action.error.message

      console.log('error occurred. message: ')
      console.log(action.error.message)
    },
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const switchDiscountsBranch = (callback: any) => async (dispatch: Dispatch, getState: AppState) => {
  const { discounts: { status } } = getState()

  if (status === Status.LOADING) return

  dispatch(resetDiscounts())
  dispatch(callback)
}

export const {
  resetDiscounts,
} = discounts.actions

export default discounts.reducer
