import axios, { AxiosResponse } from 'axios'
import * as Sentry from '@sentry/nextjs'

declare module 'axios' {
  export interface AxiosRequestConfig {
    _retry?: number
  }
}

export const setupInterceptors = () => {
  axios.interceptors.response.use(function (response: AxiosResponse) {
    if (response.config._retry) {
      const payload = response?.data?.response ? Object.values(response.data.response)?.[0] : null
      if (!payload || (Array.isArray(payload) && payload.length === 0)) {
        const responseCopy : any = Object.assign({}, response)
        delete responseCopy.config.headers

        return new Promise((resolve) => setTimeout(() => resolve(axios(responseCopy.config)), 200 + Math.random() * 300))
      }
    }

    return response
  }, function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      const payload = error.response.data

      // This is specifically a handled error from Auth0 (user does not exist)
      if(payload?.error_description?.toLowerCase() === 'public signup is disabled.') {
        return;
      }

      // This is specifically a handled error from Auth0 (user has entered an incorrect verification code)
      if(payload?.error_description?.toLowerCase() === 'wrong email or verification code.') {
        return;
      }

      const isError = payload instanceof Error
      let actualError = isError ? payload : payload.error ?? payload.message ?? payload.originalError ?? payload
      let contexts: Record<string, any> = isError ? {} : { originalError: payload }

      Sentry.captureException(
        actualError,
        contexts,
      );
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      Sentry.captureEvent({
        message: 'No response received from back-end, logging request',
        contexts: {
          request: error.request,
        },
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      Sentry.captureException(error.message);
    }
    Sentry.captureException(error);
    return Promise.reject(error);
  });
}
