// Promises

import { IronSession } from 'iron-session'
import axios, { AxiosRequestConfig } from 'axios'

export const allSettled = function (promises: Promise<any>[]) {
  let mappedPromises = promises?.map((p) => {
    return p
      .then((value) => {
        return {
          status: 'fulfilled',
          value
        }
      })
      .catch((reason) => {
        return {
          status: 'rejected',
          reason
        }
      })
  }) ?? []
  return Promise.all(mappedPromises)
}

// URLs

export const checkLinkIsExternal = (input: string) => {
  try {
    if (typeof window !== 'undefined') {
      const urlObj = new URL(input)
      return urlObj instanceof URL && window.location.host !== urlObj.host
    }
  } catch { }
  return false
}

// Clean double slashes, but preserve protocol in urls
export const cleanDoubleSlashes = (input: string) => input.replace(/([^:]\/)\/+/g, '$1')

export const getFileExtension = (url: string) => url?.split(/[#?]/)?.[0]?.split('.')?.pop()?.trim()

export const getLastSegment = (path: string) => path.substring(path.lastIndexOf('/') + 1)

export const prependHttpsProto = (input: string) => {
  const regex = /^\/\//

  return regex.test(input) ? `https:${input}` : input
}

export const hasDefaultProto = (href: string) => !(href.match(/^(?:tel|mailto):\/\//))

export const stripTrailingSlash = (input: string) => input.replace(/\/$/, '')

// Formats

export const capitalizeFirstLetter = function(str: string) {
  if(!str) {
    return str
  }

  if(str.length < 1) {
    return str
  }

  else return (str[0].toUpperCase() + str.substring(1))
}

export const formatDate = (item: string, separator = '/') => item.substring(6, 8) + separator + item.substring(4, 6) + separator + item.substring(2, 4)

export const humanFileSize = (bytes: number): `${number} ${'B' | 'KB' | 'MB' | 'GB' | 'TB'}` => {
  const index = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${Number((bytes / Math.pow(1024, index)).toFixed(2)) * 1} ${(['B', 'KB', 'MB', 'GB', 'TB'] as const)[index]}`
}

// Session

export const fetchUserAndUpdateSession = async (session: IronSession) => {
  const user = session.user

  if (!user) {
    throw new Error('No user in session')
  }

  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${process.env.AUTH0_ENV}/userinfo`,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${user.accessToken}`,
    },
  }

  const result = await axios.request(options)
  const payload = { ...result?.data }
  const locations = payload?.['https://sda.com.au/locations']
  const branches = Object.keys(locations)

  // Force the persistence of extraneous data
  const activeLocation = locations && Object.values(locations).length > 0 && (Object.values(locations)[0] as any[])[0]?.id
  payload.activeId = user.data?.activeId ?? activeLocation
  payload.employerId = user.data?.employerId ?? ''

  if (branches.find(branch => ['NSW', 'VIC', 'testing'].includes(branch))) {
    payload.accessToken = user.accessToken
  }

  const rateLimits = {
    rateLimitLimit: result.headers?.['x-ratelimit-limit'],
    rateLimitRemaining: result.headers?.['x-ratelimit-remaining'],
    rateLimitNextRefresh: result.headers?.['x-ratelimit-reset'],
    lastUpdate: Math.floor(new Date().getTime() / 1000),
  }

  session.user!.data = payload
  session.user!.rateLimiting = rateLimits
  await session.save()

  return session.user!
}

export const getActiveLocationObjectFromSession = (session: any) => {
  const branch: string = session.data?.branch ?? ''
  const activeId: string = session.data?.activeId ?? ''

  const userLocationsForBranch: Array<any> = session.data?.['https://sda.com.au/locations']?.[branch] ?? []

  return userLocationsForBranch.find((location: any) => location.id === activeId) ?? {}
}

export const getActiveBranchFromSession = (session: any) => {
  if (!session?.data) return null
  const activeId = session.data.activeId
  const userLocations = session.data['https://sda.com.au/locations']
  const allLocations = Object.values(userLocations ?? []).flat()

  return (allLocations.find((entry: any) => entry.id === activeId) as any)?.branch
}

export const getActiveLocationFromSession = (session: any) => {
  if (!session?.data) return null
  return session?.data?.employerId
}

// Other

export type GraphQLConnection = {
  edges: any[]
  pageInfo: any
}

export const mergeEntityPage = (connection: GraphQLConnection, incoming: GraphQLConnection ) => {
  const lut: Record<string, any> = connection.edges.map(edge => edge.cursor)

  for (const edge of incoming.edges) {
    if (!lut.includes(edge.cursor)) connection.edges.push(edge)
  }

  return connection
}

export const sleep = (ms: number) => new Promise(
  (resolve) => setTimeout(resolve, ms)
)

export const extractBranchAndLocationForAnalytics = (employers: any, currentEmployerId: any) => {
  let branch = ''
  let location = ''

  if (Array.isArray(employers)) {
    employers.forEach((employer)=>{
      if(employer.id === currentEmployerId) {
        branch = employer.branch
        location = employer.workplaceInfo?.employer
      }
    })
  }

  if(!branch) {
    branch = ''
  }

  if(!location) {
    location = ''
  }

  branch = branch.replace(/[^a-zA-Z0-9]/g, '_').toLocaleLowerCase()
  location = location.replace(/[^a-zA-Z0-9]/g, '_').toLocaleLowerCase()

  return {
    branch: branch,
    location: location
  }
}
