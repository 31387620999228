import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useOnlineStatus } from '@app/lib/hooks/useOnlineStatus'
import styled from 'styled-components'
import pxToRem from '@app/ui/utils/pxToRem'
import { faWifiSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Container = styled.div`
  background-color: #fff;
  border-radius: 0.4375rem;
  bottom: ${pxToRem(26)};
  box-shadow: rgba(17, 12, 46, 0.2) 0px 24px 48px 0px;
  color: #012F57;
  opacity: 1;
  padding: ${pxToRem(10)} ${pxToRem(20)};
  pointer-events: none;
  position: fixed;
  right: ${pxToRem(26)};
  transition: 0.4s opacity ease-in;
  z-index: 100;
`

export const ConnectionStatusNotifier = () => {
  const [hydrated, setHydrated] = useState(false)
  const onlineStatus = useOnlineStatus()

  useEffect(() => {
    setHydrated(true)

    return () => setHydrated(false)
  }, [])

  const modal = (
    <Container style={{ opacity: onlineStatus === 'offline' ? 1 : 0 }} >
      <FontAwesomeIcon icon={faWifiSlash} style={{ marginRight: pxToRem(10) }} />
      You are currently offline
    </Container>
  )

  return hydrated ? ReactDOM.createPortal(modal, document.getElementById('modal-root')!) : null
}
