/* eslint-disable no-restricted-globals */
let delay: number | null = null;
let nextPush: [any, string | URL | null | undefined] | null = null;
let nextReplace: [any, string | URL | null | undefined] | null = null;
let lastUpdate = -Infinity;
let isScheduled = false;

/**
 * Based on https://github.com/joliss/history-throttled
 */

declare global {
  interface History {
    _replaceState: any;
    _pushState: any;
  }
}

const replaceState: typeof history.replaceState = (state, _, url) => {
  nextReplace = [state, url];
  schedule();
};

const pushState: typeof history.pushState = (state, _, url) => {
  nextPush = [state, url];
  nextReplace = null;
  schedule();
};

function schedule() {
  if (isScheduled) {
    return;
  }
  if (delay === null) {
    // Check if we're on Safari, either desktop or mobile.
    // https://stackoverflow.com/a/31732310
    delay = navigator.vendor === 'Apple Computer, Inc.' ? 310 : 52;
  }
  let delta = performance.now() - lastUpdate;
  if (delta >= delay) {
    doWork();
  } else {
    setTimeout(doWork, delay - delta);
    isScheduled = true;
  }
}

function doWork() {
  isScheduled = false;
  lastUpdate = performance.now();
  if (nextPush !== null) {
    history._pushState(nextPush[0], '', nextPush[1]);
    nextPush = null;
    if (nextReplace !== null) {
      schedule();
    }
  } else {
    history._replaceState(nextReplace![0], '', nextReplace![1]);
    nextReplace = null;
  }
}

function setDelay(newDelay: number | null) {
  delay = newDelay;
}

if (typeof window !== 'undefined') {
  window.history._replaceState = history.replaceState
  window.history._pushState = history.pushState
  window.history.replaceState = replaceState
  window.history.pushState = pushState
}

export { pushState, replaceState, setDelay }
