import {
  combineReducers,
  CombinedState,
  Action,
  Store,
} from 'redux'
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux'

import { configureStore, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { HYDRATE, createWrapper } from 'next-redux-wrapper'
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync'
import { persistStore, persistReducer, REHYDRATE, PERSIST, REGISTER, PURGE, PAUSE, FLUSH } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import appReducer from './slices/app-slice'
import authReducer from './slices/auth-slice'
import employerReducer from './slices/employer-slice'
import discountsReducer from './slices/discounts-slice'
import noticesReducer from './slices/notices-slice'
import ebasReducer from './slices/ebas-slice'
import newsReducer from './slices/news-slice'
import eventsReducer from './slices/events-slice'
import campaignsReducer from './slices/campaigns-slice'
import formsReducer from './slices/forms-slice'

export const storePersistKey = 'sda-ma'

const RESET = 'RESET'

const persistActions = [
  PERSIST,
  REHYDRATE,
  FLUSH,
  PAUSE,
  PURGE,
  REGISTER,
]

const stringToHex = (val: string) => Number(`0x${val.split('').map(char => char.charCodeAt(0).toString(16)).join('')}`)

let store: Store<any, Action<any>>

const combinedReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  employers: employerReducer,
  discounts: discountsReducer,
  notices: noticesReducer,
  ebas: ebasReducer,
  news: newsReducer,
  events: eventsReducer,
  campaigns: campaignsReducer,
  forms: formsReducer,
})

const reducer = (state: CombinedState<any> | undefined, action: { type: string; payload: any }) => {
  if ([RESET, '@@INIT'].includes(action.type)) {
    // Remove persisted redux data
    return combinedReducer(undefined, action)
  } else if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    }

    return nextState
  } else {
    return combinedReducer(state, action)
  }
}

const makeConfiguredStore = (rootReducer: any): Store<any, Action<any>> => {
  let isPreview = false

  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search)
    isPreview = searchParams.has('preview') && searchParams.get('preview') === 'true'
  }

  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: persistActions,
        },
      })
        // Ensure that the "state sync (across tabs)" middleware runs when we have actual tabs
        .concat((typeof window !== 'undefined') ?
          createStateSyncMiddleware({
            blacklist: [
              ...persistActions,
              'auth/propagateUserData',
            ],
            channel: isPreview ? 'preview_channel' : 'redux_state_sync'
          }) : (_store) => (next) => (action) => {
            // Passthrough middleware
            next(action)
          },
        ),
  })
}

const initStore = (_preloadedState: any) => {
  const isServer = typeof window === 'undefined'

  if (isServer) {
    return makeConfiguredStore(reducer)
  } else {
    const persistConfig = {
      key: storePersistKey,
      version: stringToHex(process.env['NEXT_PUBLIC_REDUX_STORE_VERSION'] ?? '1'),
      blacklist: [
        'auth',
        'discounts',
        'notices',
        'news',
        'events',
        'campaigns',
      ], // make sure it does not clash with server keys
      storage,
      migrate: (_state: any) => {
        // No change, just acknowledgment at this time
        return Promise.resolve(_state)
      }
    }

    const persistedReducer = persistReducer(persistConfig, reducer)
    let _store = store ?? makeConfiguredStore(persistedReducer)

    const persistor = persistStore(_store, {}, function () {
      // TODO: investigate the soudness of this
      // persistor.persist()
    });

    (_store as any).__persistor = persistor

    initMessageListener(_store)

    return _store
  }
}

export type AppStore = ReturnType<typeof makeConfiguredStore>
export type AppState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector

export const wrapper = createWrapper(initStore)

export type ThunkAppDispatch = ThunkDispatch<AppState, void, Action>;

export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();