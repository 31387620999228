import { createSlice, createAsyncThunk, Dispatch } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import axios from 'axios'
import { AppState } from '..'
import { mergeEntityPage } from '@app/lib/utils'

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export const fetchEvents: any = createAsyncThunk(
  'events/fetchEvents',
  async (params: { first: number, after: string }) => {
    const response: any = await axios.get('/api/auth/events', {
      params: {
        first: params.first,
        last: null,
        after: params.after,
        before: null
      },
      _retry: 5,
    })
    return response?.data?.response
  },
)

interface IUserEventsACF {
  acfEvents: {
    events?: {
      edges: any[]
      pageInfo: any
    }
  }
  status: Status // Loading status for this data structure
  error: string | null  // A place to keep any error status for the fetch that attempts to fill this data structure
}

const initialState: IUserEventsACF = {
  acfEvents: {},
  status: Status.IDLE,
  error: null
}

export const events = createSlice({
  name: 'events',
  initialState: initialState,
  reducers: {
    resetEvents(state) {
      state.acfEvents = {}
      state.status = Status.IDLE
    },
  },
  extraReducers: {
    [fetchEvents.pending.toString()]: (state) => {
      state.status = Status.LOADING
    },
    [fetchEvents.fulfilled.toString()]: (state, action) => {
      state.status = Status.SUCCEEDED
      if (state.acfEvents?.events && action.payload?.events) {
        state.acfEvents.events = mergeEntityPage(state.acfEvents.events, action.payload.events)
        state.acfEvents.events.pageInfo = action.payload.events.pageInfo
      } else {
        state.acfEvents = action.payload
      }
    },
    [fetchEvents.rejected.toString()]: (state, action) => {
      state.status = Status.FAILED
      state.error = action.error.message

      console.log('error occurred. message: ')
      console.log(action.error.message)
    },
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const switchEventsBranch = (callback: any) => async (dispatch: Dispatch, getState: AppState) => {
  const { events: { status } } = getState()

  if (status === Status.LOADING) return

  dispatch(resetEvents())
  dispatch(callback)
}

export const {
  resetEvents,
} = events.actions

export default events.reducer
