import { AppProps } from 'next/app'
import Head from 'next/head'
import { useStore } from 'react-redux'
import { wrapper } from '../store'
import { SettingsContext } from '../lib/context/SettingsContext'
import { PersistGate } from 'redux-persist/integration/react'

// @ts-ignore
import TagManager from 'react-gtm-module'
import { useEffect } from 'react'
import { useAppSelector } from '@app/store'
import { ConnectionStatusNotifier } from '@app/ui/components/ConnectionStatusNotifier'
import { HeartbeatsNotifier } from '@app/ui/components/HeartbeatsNotifier'

import './global.css'
import '../assets/styles/hubspot-forms.css'
import { extractBranchAndLocationForAnalytics } from '@app/lib/utils'

import { setupInterceptors } from '@app/lib/axios-gateway'
import '@app/lib/throttleHistory'
import { useHubspot } from '@app/lib/hooks/useHubspot'

setupInterceptors()

function App({ Component, pageProps, router }: AppProps) {
  const store: any = useStore()

  useHubspot(router)

  const {
    employers,
    currentEmployerId,
  } = useAppSelector(state => state.employers)

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-MGXZT6V',
    })
  }, [])

  useEffect(() => {
    const branchAndLocation = extractBranchAndLocationForAnalytics(employers, currentEmployerId)

    if (branchAndLocation.branch && branchAndLocation.location) {
      TagManager.dataLayer({
        dataLayer: {
          userBranch: branchAndLocation.branch,
          userLocation: branchAndLocation.location
        },
      })
    }
  }, [employers, currentEmployerId])

  return (
    <PersistGate persistor={store.__persistor} loading={null}>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta name="robots" content="noindex" />
        <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0" />
        <title>SDA Members Area</title>
        <script src="https://www.bugherd.com/sidebarv2.js?apikey=hqqr5stccphvqzmm0u945a" async />
      </Head>
      <SettingsContext>
        <Component {...pageProps} />
        <ConnectionStatusNotifier />
        <HeartbeatsNotifier />
        <div id="modal-root" />
      </SettingsContext>
    </PersistGate>
  )
}

export default wrapper.withRedux(App)
