import { useEffect, useRef, useState, useMemo } from 'react'
import { singletonHook } from 'react-singleton-hook'
import { HEARTBEATS_DISABLED } from '@app/lib/hooks/useHeartbeats'

export const useOnlineStatus = singletonHook('offline', () => {
  const hasHeartbeats = useMemo(() => !HEARTBEATS_DISABLED, [])
  let [onlineStatus, setOnlineStatus] = useState<'online' | 'offline'>('online')
  const workerRef = useRef<Worker>()

  useEffect(() => {
    const pingWorker = new Worker('/pingWorker.js')

    if (!hasHeartbeats) {
      pingWorker.terminate() // Terminate just in case
      return // Skip if heartbeats are disabled
    }

    pingWorker.postMessage({
      action: 'init', payload: 'https://www.google.com',
    })

    pingWorker.onerror = console.error
    pingWorker.onmessage = (event) => {
      setOnlineStatus(event.data)
    }

    workerRef.current = pingWorker

    return () => {
      workerRef.current?.terminate()
    }
  }, [hasHeartbeats])

  return onlineStatus
})
