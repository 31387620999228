import { resetAuthStatus } from '@app/store/slices/auth-slice'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Settings } from '../context/Settings'
import { dataWarehouseClient, wordpressClient } from '../graphql/apolloClients'
import { cookieName } from '../hoc/withSession'
import * as Sentry from '@sentry/nextjs'
import { mutate } from 'swr'

export const useLogout = () => {
  const dispatchSettings = useContext(Settings.Dispatch)
  const dispatch = useDispatch()

  return async () => {
    // De-auth from auth0
    dispatch(resetAuthStatus())

    // Reset context
    dispatchSettings && dispatchSettings({ type: 'reset' })

    // Reset Apollo
    await dataWarehouseClient.clearStore()
    await wordpressClient.clearStore()

    // Reset redux
    dispatch({ type: 'RESET' })

    document.cookie = `${cookieName}=; Max-Age=0; path=/;`

    try {
      // Refresh swr state
      await mutate('/api/auth/user', { isLoggedIn: false })
    }
    catch (e) {
      Sentry.captureException(e)
    }
  }
}

