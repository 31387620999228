import {
  createContext,
  useReducer,
  ReactNode,
} from 'react'
import { AnyAction } from 'redux'

const initialState = {}

// Reducer
const reducer = (state: Record<string, any>, action: AnyAction) => {
  switch (action.type) {
    case 'setup':
      return {
        ...state,
        ...action.payload,
      }
    case 'reset':
      return initialState
    default:
      return state
  }
}

// Context
const State = createContext<any>(initialState)
const Dispatch = createContext<any>(null)

// Provider
const Provider = ({ children } : { children?: ReactNode | ReactNode[] }) => {
  const [state, dispatch] = useReducer(reducer, { number: 0 })

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  )
}

// Export
export const Settings = {
  State,
  Dispatch,
  Provider,
}
