
import React from 'react'
import { Settings } from './Settings'

// eslint-disable-next-line react/jsx-key
const providers = [<Settings.Provider />]

const SettingsContext = ({ children: initial } : any) =>
  providers.reduce(
    (children, parent) => React.cloneElement(parent, { children }),
    initial
  )

export { SettingsContext, Settings }
