var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"members-frontend-c62d78f8e17e2d0b6b5091f7bc0692246b8e9bbb"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

const SENTRY_DSN: string =
  (process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN) as string;

const setupSentry = function (
  environment: string,
  release = 'unknown',
  _package = 'members-frontend',
) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment,
    release,
    tracesSampleRate: 0.1,
    beforeSend(event) {
      if (environment.includes('development')) {
        return null;
      }
      return event;
    },
    initialScope: {
      tags: {
        package: `${_package}-client`,
      }
    },
    normalizeDepth: 11, // Serialize deep-nested error payloads
  });
}

axios
  .get('http://metadata.google.internal/computeMetadata/v1/project/project-id', {
    headers: {
      'Metadata-Flavor': 'Google'
    }
  })
  .then(response => setupSentry(response.data, process.env.NEXT_PUBLIC_SENTRY_RELEASE))
  .catch(() => setupSentry(String(process.env.NEXT_PUBLIC_ENV), process.env.NEXT_PUBLIC_SENTRY_RELEASE))
