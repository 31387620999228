import pxToRem from '@app/ui/utils/pxToRem'
import Link from 'next/link'
import { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getFileExtension, hasDefaultProto, prependHttpsProto } from '@app/lib/utils'

export const StyledButton = styled.a<{
  variant?: string,
}>`
  line-height: ${props => props?.variant === 'lg' ? '2.25' : '1.5'};
  display: inline-flex;
  align-items: center;
  padding-left: ${pxToRem(26)};
  padding-right: ${pxToRem(26)};
  padding-top: ${pxToRem(6.5)};
  padding-bottom: ${pxToRem(6.5)};
  background: #ff6820 0% 0% no-repeat padding-box;
  border-radius: 100px;
  font-weight: 700;
  font-size: ${props => props.theme.fontSizes?.small2x ? props.theme.fontSizes?.small2x : '0.875rem'};
  color: #ffffff;
  text-decoration: none !important;
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  &:disabled {
    opacity: 0.25;
  }
  &:hover,
  &:visited,
  &:active,
  &:focus {
    color: #ffffff;
  }
  &:hover {
    background-color: #f2560b;
    color: white;
  }
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: ${pxToRem(10)};
`

interface ButtonLinkProps {
  href: string
  target?: string
  className?: string
}

export const ButtonLink: FC<PropsWithChildren<ButtonLinkProps>> = ({ href, children, target, className }) => {
  const variant = className?.substring(className?.indexOf('btn-') + 4)
  const isHttp = hasDefaultProto(href)

  const FQUrl = prependHttpsProto(href)

  const opensInNewTab = target && !['_self', '_parent', '_top'].includes(target)
  const isPdf = getFileExtension(href) === 'pdf'

  return <Link href={FQUrl ?? href ?? '#'} passHref legacyBehavior>
    <StyledButton variant={variant} target={target}>
      {children}
      {opensInNewTab && isHttp && !isPdf && <StyledIcon icon={['far', 'external-link']} />}
    </StyledButton>
  </Link>
}

export default ButtonLink
