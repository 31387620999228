import { useEffect, useRef, useState, useMemo } from 'react'
import { DATA_WAREHOUSE_GRAPHQL_HOST } from '@app/lib/urls'

export const HEARTBEATS_DISABLED = Boolean(process.env.NEXT_PUBLIC_DISABLE_HEARTBEATS)

export const useHeartbeats = () => {
  const hasHeartbeats = useMemo(() => !HEARTBEATS_DISABLED, [])
  const workersList = useRef<Worker[]>()
  const [dwIsDown, setDWIsDown] = useState<boolean>(false)
  const [cmsIsDown, setCMSIsDown] = useState<boolean>(false)

  useEffect(() => {
    const pingWorker = new Worker('/pingWorker.js')

    if (!hasHeartbeats) {
      pingWorker.terminate() // Terminate just in case
      return // Skip if heartbeats are disabled
    }

    workersList.current = [
      {
        target: `${DATA_WAREHOUSE_GRAPHQL_HOST}/.well-known/apollo/server-health`,
        messageCallback: (event: { data: 'online' | 'offline' }) => {
          setDWIsDown(event.data === 'offline')
        },
      },
      {
        target: process.env['NEXT_PUBLIC_WORDPRESS_ENDPOINT'],
        messageCallback: (event: { data: 'online' | 'offline' }) => {
          setCMSIsDown(event.data === 'offline')
        },
      },
    ].map(({ target, messageCallback }) => {
      pingWorker.postMessage({
        action: 'init', payload: target,
      })

      pingWorker.onerror = console.error
      pingWorker.onmessage = messageCallback
      return pingWorker
    })

    return () => {
      workersList.current?.forEach(worker => {
        worker.terminate()
      })
    }
  }, [hasHeartbeats])

  return {
    dwIsDown,
    cmsIsDown,
  }
}
