import { ApolloClient, ApolloLink, DefaultOptions, HttpLink, InMemoryCache } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/nextjs'
import { GraphQLError } from 'graphql'
import { DATA_WAREHOUSE_GRAPHQL_HOST, WORDPRESS_GRAPHQL_URL } from '@app/lib/urls'

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ operation, response, graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error: GraphQLError) => {
      const { message, locations, path, originalError } = error

      const errorDetails = {
        operation,
        response,
        message,
        locations,
        path,
        originalError,
      }

      Sentry.captureException(
        error,
        {
          contexts: {
            errorDetails,
          },
        },
      )
    })
  if (networkError) {
    console.log(`[Network error (apollo client)]: ${networkError}`)
    Sentry.captureException(networkError)
  }
})

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const httpLogger = new ApolloLink((operation, forward) => {
  Sentry.captureMessage('request', {
    tags: {
      domain: 'apollo',
    },
    contexts: operation.getContext(),
  })
  return forward(operation).map((result) => {
    const graphQLContext = operation.getContext()

    const allKeys = [...Object.keys(graphQLContext.response), ...Object.getOwnPropertySymbols(graphQLContext.response)]

    const niceResponse = {
      size: graphQLContext.response.size,
      timeout: graphQLContext.response.timeout,
      content: graphQLContext.response?.[allKeys?.[3]] ?? 'n/a'
    }

    Sentry.captureMessage('response', {
      tags: {
        domain: 'apollo',
      },
      contexts: {
        ...graphQLContext,
        niceResponse,
      },
    })
    return result
  })
})

const defaultCache = new InMemoryCache()

const noCacheOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error
  }
})

const queryLogger = (...pl: [info: RequestInfo | URL, init: RequestInit | undefined]) => {
  if (!process.env['LOG_QUERIES']) return fetch(...pl)
  const [url, options] = pl
  const body = JSON.parse(options?.body as string)
  console.log('🔗 ', url)
  console.log('🗣️ ', { headers: options?.headers })
  console.log(`📡 ${body.operationName || ''}\n${body.query}`, body.variables)
  return fetch(...pl)
}

export const wordpressClient = new ApolloClient({
  assumeImmutableResults: true,
  cache: defaultCache,
  queryDeduplication: false,
  link: ApolloLink.from([
    // TODO: consider http logger to send additional output (request/response) to Sentry
    // httpLogger,
    errorLink,
    retryLink,
    new HttpLink({
      uri: WORDPRESS_GRAPHQL_URL,
      fetch: queryLogger,
    }),
  ]),
  defaultOptions: noCacheOptions,
})

export const dataWarehouseClient = new ApolloClient({
  cache: defaultCache,
  queryDeduplication: false,
  link: ApolloLink.from([
    errorLink,
    retryLink,
    new HttpLink({
      uri: `${DATA_WAREHOUSE_GRAPHQL_HOST}/graphql`,
      fetch: queryLogger,
    }),
  ]),
  defaultOptions: noCacheOptions,
})
