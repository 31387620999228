import { gql } from '@apollo/client'
import { cloneDeep } from '@apollo/client/utilities'
import { wordpressClient } from '@app/lib/graphql/apolloClients'
import { getActiveBranchFromSession, getActiveLocationFromSession } from '../utils'

export type WordPressPageQuery = {
  params: any
  preview: boolean
  // previewData: any
  query: Record<string, any>
  req: any
}

export type WordPressPage = {
  id: number
  slug: string
  status: 'draft' | 'publish'
  revisions: Record<string, any>
}

export type WordPressImage = {
  mimeType: string
  title: string
  altText: string
  url: string
}

export enum WordPressLocationEnum {
  MOBILE_NAVIGATION = 'MOBILE_NAVIGATION',
  PRIMARY_NAVIGATION = 'PRIMARY_NAVIGATION',
}

export type MenuNode = {
  [index: string]: any
  idKey?: string
  parentKey?: string
  childrenKey?: string
}

export async function inflateMenuHierarchy(
  data = [],
  { idKey = 'key', parentKey = 'parentId', childrenKey = 'menu' }: MenuNode = {},
) {
  const tree: MenuNode[] = []
  const childrenOf: Record<string, any> = {}
  data.forEach((item: MenuNode) => {
    const newItem = { ...item }
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
    childrenOf[id as string] = childrenOf[id as string] ?? []
    newItem[childrenKey] = childrenOf[id as string]
    parentId
      ? (
        childrenOf[parentId] = childrenOf[parentId] ?? []
      ).push(newItem)
      : tree.push(newItem)
  })

  return tree
}

export const formatSidebarCTA = (data: any[]) =>
  data?.map(item => ({
    ctaTitle: item?.ctaText,
    ctaImage: item?.image?.mediaItemUrl ?? null,
    ctaLink: item?.ctaLink?.url ?? null,
    ctaLinkTarget: item.ctaLink?.target ?? null,
    isDownload: item?.isDownload,
  })) ?? []

const undefPropsToNull = (obj: any) => {
  Object.entries(obj).forEach(([key, val]) => {
    obj[key] = val ?? null
  })

  return obj
}

export const formatBranchSettings = (data: any) => {
  const settings = data?.branchSettingsPages?.nodes?.[0]?.branchSettings

  const mailSettings = undefPropsToNull({
    toAddress: settings?.toAddress,
    replyTo: settings?.replyTo,
  })

  return undefPropsToNull({
    email: settings?.email,
    phone: settings?.phone,
    privacyPolicy: settings?.privacyPolicy,
    disclaimer: settings?.disclaimer,
    representativeTitle: settings?.representativeTitle,
    secretaryName: settings?.secretaryName,
    secretaryImage: settings?.secretaryImage?.mediaItemUrl,
    secretaryTitle: settings?.secretaryTitle,
    social: settings?.social,
    mailSettings: mailSettings,
    discountsCtaImage: settings?.discountsCtaImage?.mediaItemUrl,
    discountsListingsPageSubtitle: settings?.discountsListingsPageSubtitle,
    discountsListingsHideCta: settings?.hideDiscountsCta,
    workplaceCtaText: settings?.workplaceCtaUrl?.title,
    workplaceCtaUrl: settings?.workplaceCtaUrl?.url,
    workplaceCtaTarget: settings?.workplaceCtaUrl?.target,
    eventsCtaImage: settings?.eventsCtaImage?.mediaItemUrl,
    eventsListingsPageSubtitle: settings?.eventsListingsPageSubtitle,
    eventsListingsHideCta: settings?.hideEventsCta,
    primaryWidget: settings?.primaryWidget,
    secondaryWidget: settings?.secondaryWidget,
    newsListingFallbackFeaturedImage: settings?.newsListingFallbackFeaturedImage,
    campaignsListingFallbackFeaturedImage: settings?.campaignsListingFallbackFeaturedImage,
    eventsListingFallbackFeaturedImage: settings?.eventsListingFallbackFeaturedImage,
    issuesAtWorkAskAQuestion: settings?.issuesAtWorkAskAQuestion,
  })
}

export function parseAltBGQueryResults(flexibleContent: any[]) {
  const output: any[] = []

  let arrayPointer = output

  flexibleContent?.forEach((flexibleRow: Record<string, any>) => {
    if (flexibleRow?.__typename?.includes('AltBgStart')) {
      const startMarker = {
        ...flexibleRow,
        __typename: flexibleRow?.__typename?.replace('AltBgStart', 'AltBg'),
        flexibleContent: []
      }
      output.push(startMarker)
      arrayPointer = startMarker.flexibleContent
    }
    else if (flexibleRow?.__typename?.includes('AltBgEnd')) {
      if (arrayPointer === output) console.error('Parser error: AltBgEnd without matching AltBgStart')
      arrayPointer = output
    }
    else {
      arrayPointer.push(flexibleRow)
    }
  })

  return output
}

export async function getAllPagesWithURI() {
  const { data } = await wordpressClient.query({
    query: gql`
    {
      pages(first: 10000) {
        edges {
          node {
            uri
          }
        }
      }
    }
  `,
  })
  return data?.pages
}

function getWordpressDomain() {
  let domain = 'https://cms.members.sda.atomix.dev'
  if (window.location.href.indexOf('local') !== -1) {
    domain = 'https://members-cms.local.dev'
  }

  return domain
}

async function doWordpressGraphQLFetch(query: any) {
  const endpoint = `${getWordpressDomain()}/graphql`

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify({
      query,
    })
  })
  const json = await response.json()
  return json.data
}

export async function getParticularAwardTheOldSchoolWay(postId: number | string) {
  const query = `
      query Award {
        award(id: "${postId}", idType: DATABASE_ID) {
          id
          title
          awardCptFields {
            awardThumbnail {
              mediaItemUrl
            }
            ebaTitle
            currentFrom
            bodySummaryText
            documentSummaryText
            ebaSummaryDocument {
              mediaItemUrl
            }
            faqContent {
              partTimeHours
              publicHolidays
              yourBreaks
              yourLeaveEntitlements
              yourPenaltyRates
              yourRoster
            }
            fullEbaDocument {
              mediaItemUrl
            }
            fullWageTable {
              mediaItemUrl
            }
            wageTablePdf {
              mediaItemUrl
            }
          }
        }
      }
  `
  const data: any = doWordpressGraphQLFetch(query)

  return data
}

export async function getSiteSettings() {
  const { data } = await wordpressClient.query<any>({
    query: gql`
        query SiteSettings {
          theme(id: "") {
            id
          }
          themeGeneralSettings {
            id: pageSlug
            siteSettings {
              colophon
              disclaimer
              privacyPolicy
              email
              phone
              footerLogo {
                altText
                mimeType
                srcSet
                sourceUrl
              }
              headerLogo {
                altText
                mimeType
                srcSet
                sourceUrl
              }
              social {
                socialNetwork
                url
              }
              loginTroubleshootingAccordions {
                title
                column1
              }
              salaryEba {
                ... on Award {
                  title
                  awardCptFields {
                    noAvailablePayrate
                    awardThumbnail {
                      mediaItemUrl
                    }
                    ebaTitle
                    bodySummaryText
                    documentSummaryText
                    fullEbaDocument {
                      mediaItemUrl
                    }
                    faqContent {
                      partTimeHours
                      publicHolidays
                      yourBreaks
                      yourLeaveEntitlements
                      yourPenaltyRates
                      yourRoster
                    }
                  }
                }
              }
            }
          }
        },
    `,
  })

  const discountsListingsPageSubtitle = data?.themeGeneralSettings?.siteSettings?.discountsListingsPageSubtitle ?? null
  const discountsCTAImageUrl = data?.themeGeneralSettings?.siteSettings?.discountsCtaImage?.mediaItemUrl ?? null
  const awardsFallbackContent = null

  return {
    ...data?.themeGeneralSettings?.siteSettings, ...{
      discountsListingsPageSubtitle,
      discountsCTAImageUrl,
      awardsFallbackContent,
    }
  }
}


export class AuthedClient {
  constructor(
    private accessToken: string,
    private defaultId?: string,
    private defaultBranch?: string,
    private defaultLocation?: string,
  ) { }

  /**
   *
   * @param location
   * @param activeId
   * @param activeBranch
   * @returns
   */
  getMenu = async (
    location: WordPressLocationEnum,
    preview = false,
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
  ) => {
    if (preview) return [{}]

    let context: Record<string, any> = {}

    if (this.accessToken) {
      context = {
        headers: {
          'x-authorization': `Bearer ${this.accessToken}`,
          'x-member-id': activeId ?? this.defaultId,
          'x-member-branch': activeBranch ?? this.defaultBranch,
          'x-member-location': activeLocation ?? this.defaultLocation,
        }
      }
    }

    if (preview) {
      context.headers = {
        ...context.headers,
        'x-request-mode': 'preview',
        'x-menu-preview': true,
      }
    }

    const { data } = await wordpressClient.query<any>({
      query: gql`query MainMenu($location: MenuLocationEnum) {
        menus(where: {location: $location}) {
          nodes {
            menuItems(first: 10000) {
              nodes {
                key: id
                target
                label
                path: url
                parentId
                menuSettings {
                  icon
                }
                connectedNode {
                  node {
                    ... on Page {
                      id
                      modified
                      fcpPageOptions {
                        customTitle
                      }
                    }
                    ... on ContentNode {
                      modified
                      contentType {
                        node {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`,
      variables: {
        location,
      },
      context,
    })

    return inflateMenuHierarchy(data?.menus?.nodes?.[0]?.menuItems?.nodes ?? [])
  }

  /**
   *
   * @param branchName
   * @returns
   */
  getBranchSettings = async (
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
  ) => {
    let context: Record<string, any> = {}

    if (this.accessToken) {
      context = {
        headers: {
          'x-authorization': `Bearer ${this.accessToken}`,
          'x-member-id': activeId ?? this.defaultId,
          'x-member-branch': activeBranch ?? this.defaultBranch,
          'x-member-location': activeLocation ?? this.defaultLocation,
        }
      }
    }

    const { data } = await wordpressClient.query<any>({
      query: gql`query BranchSettings($branchName: String) {
        branchSettingsPages(where: {sdaBranch: $branchName}) {
          nodes {
            branchSettings {
              email
              phone
              privacyPolicy
              disclaimer
              representativeTitle
              social {
                socialNetwork
                url
              }
              secretaryName
              secretaryImage {
                mediaItemUrl
              }
              secretaryTitle
              sidebarMenuPromotionCards {
                ctaText
                ctaLink {
                  target
                  title
                  url
                }
                image {
                  mediaItemUrl
                }
                isDownload
              }
              toAddress
              replyTo
              hideDiscountsCta
              discountsCtaImage {
                mediaItemUrl
              }
              discountsListingsPageSubtitle
              hideEventsCta
              eventsCtaImage {
                mediaItemUrl
              }
              eventsListingsPageSubtitle
              primaryWidget {
                featuredImage {
                  mediaItemUrl
                }
                title
                cta {
                  target
                  title
                  url
                }
              }
              secondaryWidget {
                featuredImage {
                  mediaItemUrl
                }
                title
                description
                ctaLink {
                  target
                  title
                  url
                }
              }
              newsListingFallbackFeaturedImage {
                mediaItemUrl
              }
              workplaceCtaUrl {
                target
                title
                url
              }
              campaignsListingFallbackFeaturedImage {
                mediaItemUrl
              }
              eventsListingFallbackFeaturedImage {
                mediaItemUrl
              }
              eventsCtaImage {
                mediaItemUrl
              }
              issuesAtWorkAskAQuestion {
                ... on Page {
                 uri
               }
             }
            }
          }
        }
      }
      `,
      context,
      variables: {
        branchName: activeBranch ?? this.defaultBranch,
      },
    })

    const sidebarCTA = formatSidebarCTA(
      data
        ?.branchSettingsPages
        ?.nodes?.
      [0]
        ?.branchSettings
        ?.sidebarMenuPromotionCards
    )

    console.log({
      branchSettings: data,
      formattedBranchSettings: formatBranchSettings(data),
      sidebarCTA,
    })

    return {
      ...formatBranchSettings(data),
      sidebarCTA,
    }
  }

  /**
   *
   * @param id
   * @param preview
   * @param _preview_nonce
   * @param activeId
   * @param activeBranch
   * @returns
   */
  getPage = async (
    id: string,
    preview = true,
    _preview_nonce?: string,
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
  ) => {
    const hideOnDeviceFields = `
    hideDesktop
    hideMobile`

    let context: Record<string, any> = {}

    if (this.accessToken) {
      context.headers = {
        'x-authorization': `Bearer ${this.accessToken}`,
        'x-member-id': activeId ?? this.defaultId,
        'x-member-branch': activeBranch ?? this.defaultBranch,
        'x-member-location': activeLocation ?? this.defaultLocation,
      }
    }

    if (preview) {
      context.headers = {
        ...context.headers,
        'x-post-id': id,
        'x-request-mode': 'preview',
        'x-preview-nonce': _preview_nonce ?? '',
      }
    }

    const { error, data } = await wordpressClient.query<{
      page: any,
      preview: any,
    }>({
      query: gql`
      fragment PageFields on Page {
        title
        slug
        date
        status
        fcpPageOptions {
          pageSubtitle
          customTitle
          issuesAtWorkEnabled: showHavingIssuesAtWorkWidget
        }
        universalContentEditor {
          flexibleContent {
            __typename
            ... on Page_Universalcontenteditor_FlexibleContent_Accordions {
              accordion {
                columns
                column1
                column2
                title
              }
              ${hideOnDeviceFields}
            }
            ... on Page_Universalcontenteditor_FlexibleContent_AltBgStart {
              backgroundColour
            }
            ... on Page_Universalcontenteditor_FlexibleContent_CallToAction {
              buttonLinkInternal {
                ... on AcfLink {
                  url
                }
                ... on Post {
                  id
                  url: uri
                }
                ... on Page {
                  id
                  url: uri
                }
                ... on MediaItem {
                  id
                  mediaItemUrl: link
                }
              }
              buttonLinkExternal
              buttonText
              subheading: callToActionText
              internal
              ${hideOnDeviceFields}
            }
            ... on Page_Universalcontenteditor_FlexibleContent_Downloads {
              twoColumnLayout
              downloads {
                downloadTitle
                download {
                  fileSize
                  link: mediaItemUrl
                }
              }
              ${hideOnDeviceFields}
            }
            ... on Page_Universalcontenteditor_FlexibleContent_Form {
              formId
              submissionLimiter
              replyTo
              adminNotifications {
                emailAddress
              }
              thankYouMessage
              iWouldLikeToUseAThankYouPage
              thankYouPage {
                ... on Post {
                  id
                  url: uri
                }
                ... on Page {
                  id
                  url: uri
                }
                ... on MediaItem {
                  id
                  mediaItemUrl: link
                }
                ... on AcfLink {
                  url
                }
              }
            }
            ... on Page_Universalcontenteditor_FlexibleContent_Gallery {
              columns
              title
              images: gallery {
                altText
                mimeType
                caption
                title
                description
                imageThumbnail: sourceUrl(size: THUMBNAIL)
                imageFull: mediaItemUrl
              }
              ${hideOnDeviceFields}
            }
            ... on Page_Universalcontenteditor_FlexibleContent_Heading {
              centered
              heading
              headingType
              ${hideOnDeviceFields}
            }
            ... on Page_Universalcontenteditor_FlexibleContent_OneColumnRow {
              html: column1
              ${hideOnDeviceFields}
            }
            ... on Page_Universalcontenteditor_FlexibleContent_TwoColumnRow {
              fullWidth
              twoColumnColumn1: column1 {
                html: col1
                hideDesktop: hideOnDesktopCol1
                hideMobile: hideOnMobileCol1
              }
              twoColumnColumn2: column2 {
                html: col2
                hideDesktop: hideOnDesktopCol2
                hideMobile: hideOnMobileCol2
              }
              verticallyCenter
            }
            ... on Page_Universalcontenteditor_FlexibleContent_TwoColumnSidebarRow {
              sidebarColumn1: column1
              sidebarColumn2: column2
              verticalAlignment
              ${hideOnDeviceFields}
            }
            ... on Page_Universalcontenteditor_FlexibleContent_Spacer {
              ${hideOnDeviceFields}
              lineBreak
            }
          }
        }
      }
      query PageBySlug($id: ID!, $idType: PageIdType!, $preview: Boolean) {
        page(id: $id, idType: $idType, asPreview: $preview) {
          ...PageFields
        }
      }
    `,
      variables: {
        id: id,
        idType: preview ? 'DATABASE_ID' : 'URI',
        preview,
      },
      context,
    })

    const result: Record<string, any> = {
      page: {
        ...data?.page, ...{
          universalContentEditor: {
            __typename: 'Page_Universalcontenteditor',
            flexibleContent: parseAltBGQueryResults(data?.page?.universalContentEditor.flexibleContent)
          }
        },
      }
    }

    if (error) {
      result.error = error
    }

    return result
  }

  /**
   *
   * @param id
   * @param preview
   * @param _preview_nonce
   * @param activeId
   * @param activeBranch
   * @returns
   */
  checkPageExists = async (
    id: string,
    preview = false,
    _preview_nonce?: string,
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
  ) => {
    let context: Record<string, any> = {}

    if (this.accessToken) {
      context.headers = {
        'x-authorization': `Bearer ${this.accessToken}`,
        'x-member-id': activeId ?? this.defaultId,
        'x-member-branch': activeBranch ?? this.defaultBranch,
        'x-member-location': activeLocation ?? this.defaultLocation,
      }
    }

    if (preview) {
      context.headers = {
        ...context.headers,
        'x-post-id': id,
        'x-request-mode': 'preview',
        'x-preview-nonce': _preview_nonce ?? '',
      }
    }

    const { error, data } = await wordpressClient.query<{
      page: any,
      preview: any,
    }>({
      query: gql`
      fragment PageFields on Page {
        title
        slug
      }
      query ProbeBySlug($id: ID!, $idType: PageIdType!, $preview: Boolean) {
        page(id: $id, idType: $idType, asPreview: $preview) {
          ...PageFields
        }
      }
    `,
      variables: {
        id: id,
        idType: preview ? 'DATABASE_ID' : 'URI',
        preview,
      },
      context,
    })

    const result: Record<string, any> = {
      page: {
        ...data?.page,
      }
    }

    if (error) {
      result.error = error
    }

    return result
  }

  /**
   *
   * @param first
   * @param last
   * @param after
   * @param before
   * @param branchName
   * @param activeId
   * @param activeBranch
   * @returns
   */
  getPaginatedDiscounts = async (
    first: number | null,
    last: number | null,
    after: string | null,
    before: string | null,
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
  ) => {
    let context: any = {}

    if (this.accessToken) {
      context = {
        headers: {
          'x-authorization': `Bearer ${this.accessToken}`,
          'x-member-id': activeId ?? this.defaultId,
          'x-member-branch': activeBranch ?? this.defaultBranch,
          'x-member-location': activeLocation ?? this.defaultLocation,
        }
      }
    }

    const { data } = await wordpressClient.query<any>({
      query: gql`query getPaginatedBenefits (
        $first: Int
        $last: Int
        $after: String
        $before: String
        $branchName: String
      ) {
        benefits (first: $first, last: $last, after: $after, before: $before, where: {sdaBranch: $branchName}) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            total
          }
          edges {
            cursor
            node {
              benefitsFields {
                displayTitle
                featuredImage {
                  mediaItemUrl
                }
                subtitle
                linkText
              }
              benefitId
              date
              modified
              title
              uri
            }
          }
        }
      }`,
      variables: {
        first,
        last,
        after,
        before,
        branchName: activeBranch ?? this.defaultBranch,
      },
      context,
    })

    return data
  }

  /**
   *
   * @param slug
   * @param preview
   * @param _preview_nonce
   * @param activeId
   * @param activeBranch
   * @returns
   */
  getSingleDiscount = async (
    slug: string,
    preview = false,
    _preview_nonce?: string,
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
  ) => {
    let context: Record<string, any> = {}

    if (this.accessToken) {
      context = {
        headers: {
          'x-authorization': `Bearer ${this.accessToken}`,
          'x-member-id': activeId ?? this.defaultId,
          'x-member-branch': activeBranch ?? this.defaultBranch,
          'x-member-location': activeLocation ?? this.defaultLocation,
        }
      }
    }

    if (preview) {
      context.headers = {
        ...context.headers,
        'x-post-id': slug,
        'x-request-mode': 'preview',
        'x-preview-nonce': _preview_nonce ?? '',
      }
    }

    const { data } = await wordpressClient.query<any>({
      query: gql`query singleBenefit($slug: ID!, $idType: BenefitIdType!, $preview: Boolean) {
        benefit(id: $slug, idType: $idType, asPreview: $preview) {
          benefitsFields {
            displayTitle
            featuredImage {
              mediaItemUrl
            }
            subtitle
            linkText
            flexibleContentBenefits {
              __typename
              ... on Benefit_Benefitsfields_FlexibleContentBenefits_CallToAction {
                buttonLinkInternal {
                  ... on Benefit {
                    id
                    url: uri
                  }
                }
                buttonLinkExternal
                buttonText
                subheading: callToActionText
                internal
                hideDesktop
                hideMobile
              }
              ... on Benefit_Benefitsfields_FlexibleContentBenefits_Gallery {
                columns
                title
                images: gallery {
                  altText
                  mimeType
                  caption
                  title
                  description
                  imageThumbnail: sourceUrl(size: THUMBNAIL)
                  imageFull: mediaItemUrl
                }
                hideDesktop
                hideMobile
              }
              ... on Benefit_Benefitsfields_FlexibleContentBenefits_Heading {
                centered
                heading
                headingType
                hideDesktop
                hideMobile
              }
              ... on Benefit_Benefitsfields_FlexibleContentBenefits_OneColumnRow {
                html: column1
                hideDesktop
                hideMobile
              }
              ... on Benefit_Benefitsfields_FlexibleContentBenefits_TwoColumnRow {
                fullWidth
                twoColumnColumn1: column1 {
                  html: col1
                  hideDesktop: hideOnDesktopCol1
                  hideMobile: hideOnMobileCol1
                }
                twoColumnColumn2: column2 {
                  html: col2
                  hideDesktop: hideOnDesktopCol2
                  hideMobile: hideOnMobileCol2
                }
                verticallyCenter
              }
              ... on Benefit_Benefitsfields_FlexibleContentBenefits_TwoColumnSidebarRow {
                sidebarColumn1: column1
                sidebarColumn2: column2
                sidebar
                sidebarBackground
                sidebarWidth
              }
              ... on Benefit_Benefitsfields_FlexibleContentBenefits_AltBgStart {
                backgroundColour
              }
              ... on Benefit_Benefitsfields_FlexibleContentBenefits_Spacer {
                hideDesktop
                hideMobile
                lineBreak
              }
            }
          }
          date
          title
          uri
        }
      }`,
      variables: {
        slug,
        idType: preview ? 'DATABASE_ID' : 'URI',
        preview,
      },
      context,
    })

    const result = cloneDeep(data)

    if (result?.benefit?.benefitsFields?.flexibleContentBenefits) {
      result.benefit.benefitsFields.flexibleContentBenefits = parseAltBGQueryResults(result.benefit.benefitsFields.flexibleContentBenefits)
    }

    return result
  }

  /**
   *
   * @param first
   * @param last
   * @param after
   * @param before
   * @param branchName
   * @param activeId
   * @param activeBranch
   * @returns
   */
  getPaginatedNotices = async (
    first: number | null,
    last: number | null,
    after: string | null,
    before: string | null,
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
  ) => {
    let context = {}

    if (this.accessToken) {
      context = {
        headers: {
          'x-authorization': `Bearer ${this.accessToken}`,
          'x-member-id': activeId ?? this.defaultId,
          'x-member-branch': activeBranch ?? this.defaultBranch,
          'x-member-location': activeLocation ?? this.defaultLocation,
        }
      }
    }

    const { data } = await wordpressClient.query<any>({
      query: gql`query getPaginatedNotices(
        $first: Int
        $last: Int
        $after: String
        $before: String
        $branchName: String
      ) {
      notices(first: $first, last: $last, after: $after, before: $before, where: {sdaBranch: $branchName}) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          total
        }
        edges{
          cursor
          node {
            date
            modified
            title
            uri
            noticeId
            legalNoticesFcp {
              postExcerpt
            }
          }
        }
      }
    }`,
      variables: {
        first,
        last,
        after,
        before,
        branchName: activeBranch ?? this.defaultBranch,
      },
      context,
    })

    return data
  }

  /**
   *
   * @param slug
   * @param preview
   * @param _preview_nonce
   * @param activeId
   * @param activeBranch
   * @returns
   */
  getSingleNotice = async (
    slug: string,
    preview = false,
    _preview_nonce?: string,
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
  ) => {
    let context: Record<string, any> = {}

    if (this.accessToken) {
      context = {
        headers: {
          'x-authorization': `Bearer ${this.accessToken}`,
          'x-member-id': activeId ?? this.defaultId,
          'x-member-branch': activeBranch ?? this.defaultBranch,
          'x-member-location': activeLocation ?? this.defaultLocation,
        }
      }
    }

    if (preview) {
      context.headers = {
        ...context.headers,
        'x-post-id': slug,
        'x-request-mode': 'preview',
        'x-preview-nonce': _preview_nonce ?? '',
      }
    }

    const { data } = await wordpressClient.query<any>({
      query: gql`query getSingleNotice($slug: ID!, $idType: NoticeIdType!, $preview: Boolean) {
        notice(id: $slug, idType: $idType, asPreview: $preview) {
          date
          title
          uri
          revisionOf {
            node {
              date
            }
          }
          legalNoticesFcp {
            postExcerpt
            flexibleContentNews {
              __typename
              ... on Notice_Legalnoticesfcp_FlexibleContentNews_CallToAction {
                buttonLinkInternal {
                  ... on Notice {
                    id
                    url: uri
                  }
                }
                buttonLinkExternal
                buttonText
                subheading: callToActionText
                internal
                hideDesktop
                hideMobile
              }
              ... on Notice_Legalnoticesfcp_FlexibleContentNews_Gallery {
                columns
                title
                images: gallery {
                  altText
                  mimeType
                  caption
                  title
                  description
                  imageThumbnail: sourceUrl(size: THUMBNAIL)
                  imageFull: mediaItemUrl
                }
                hideDesktop
                hideMobile
              }
              ... on Notice_Legalnoticesfcp_FlexibleContentNews_Heading {
                centered
                heading
                headingType
                hideDesktop
                hideMobile
              }
              ... on Notice_Legalnoticesfcp_FlexibleContentNews_OneColumnRow {
                html: column1
                hideDesktop
                hideMobile
              }
              ... on Notice_Legalnoticesfcp_FlexibleContentNews_Spacer {
                hideDesktop
                hideMobile
                lineBreak
              }
              ... on Notice_Legalnoticesfcp_FlexibleContentNews_Form {
                formId
                submissionLimiter
                replyTo
                adminNotifications {
                  emailAddress
                }
                thankYouMessage
                iWouldLikeToUseAThankYouPage
                thankYouPage {
                  ... on Post {
                    id
                    url: uri
                  }
                  ... on Page {
                    id
                    url: uri
                  }
                  ... on MediaItem {
                    id
                    mediaItemUrl: link
                  }
                  ... on AcfLink {
                    url
                  }
                }
              }
            }
          }
        }
      }`,
      variables: {
        slug,
        idType: preview ? 'DATABASE_ID' : 'URI',
        preview,
      },
      context,
    })

    return data
  }

  /**
   *
   * @param first
   * @param last
   * @param after
   * @param before
   * @param branchName
   * @param activeId
   * @param activeBranch
   * @returns
   */
  getPaginatedNews = async (
    first: number | null,
    last: number | null,
    after: string | null,
    before: string | null,
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
  ) => {
    let context = {}

    if (this.accessToken) {
      context = {
        headers: {
          'x-authorization': `Bearer ${this.accessToken}`,
          'x-member-id': activeId ?? this.defaultId,
          'x-member-branch': activeBranch ?? this.defaultBranch,
          'x-member-location': activeLocation ?? this.defaultLocation,
        }
      }
    }

    const { data } = await wordpressClient.query<any>({
      query: gql`query getPaginatedNews(
        $first: Int
        $last: Int
        $after: String
        $before: String
        $branchName: String
      ) {
      newsArticles(first: $first, last: $last, after: $after, before: $before, where: {sdaBranch: $branchName}) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          total
        }
        edges{
          cursor
          node {
            date
            modified
            title
            uri
            newsArticleId
            newsFcp {
              postExcerpt
              featuredImage {
                mediaItemUrl
              }
            }
          }
        }
      }
    }`,
      variables: {
        first,
        last,
        after,
        before,
        branchName: activeBranch ?? this.defaultBranch,
      },
      context,
    })

    return data
  }

  /**
   *
   * @param slug
   * @param preview
   * @param _preview_nonce
   * @param activeId
   * @param activeBranch
   * @returns
   */
  getSingleNewsArticle = async (
    slug: string,
    preview = false,
    _preview_nonce?: string,
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
  ) => {
    let context: Record<string, any> = {}

    if (this.accessToken) {
      context = {
        headers: {
          'x-authorization': `Bearer ${this.accessToken}`,
          'x-member-id': activeId ?? this.defaultId,
          'x-member-branch': activeBranch ?? this.defaultBranch,
          'x-member-location': activeLocation ?? this.defaultLocation,
        }
      }
    }

    if (preview) {
      context.headers = {
        ...context.headers,
        'x-post-id': slug,
        'x-request-mode': 'preview',
        'x-preview-nonce': _preview_nonce ?? '',
      }
    }

    const { data } = await wordpressClient.query<any>({
      query: gql`query getSingleNewsArticle($slug: ID!, $idType: NewsArticleIdType!, $preview: Boolean) {
        newsArticle(id: $slug, idType: $idType, asPreview: $preview) {
          date
          title
          uri
          revisionOf {
            node {
              date
            }
          }
          newsFcp {
            postExcerpt
            featuredImage {
              mediaItemUrl
            }
            flexibleContentNews {
              __typename
              ... on NewsArticle_Newsfcp_FlexibleContentNews_CallToAction {
                buttonLinkInternal {
                  ... on NewsArticle {
                    id
                    url: uri
                  }
                }
                buttonLinkExternal
                buttonText
                subheading: callToActionText
                internal
                hideDesktop
                hideMobile
              }
              ... on NewsArticle_Newsfcp_FlexibleContentNews_Gallery {
                columns
                title
                images: gallery {
                  altText
                  mimeType
                  caption
                  title
                  description
                  imageThumbnail: sourceUrl(size: THUMBNAIL)
                  imageFull: mediaItemUrl
                }
                hideDesktop
                hideMobile
              }
              ... on NewsArticle_Newsfcp_FlexibleContentNews_Heading {
                centered
                heading
                headingType
                hideDesktop
                hideMobile
              }
              ... on NewsArticle_Newsfcp_FlexibleContentNews_ArticleSummary {
                title
                summaryListItems {
                  listItem
                }
              }
              ... on NewsArticle_Newsfcp_FlexibleContentNews_OneColumnRow {
                html: column1
                hideDesktop
                hideMobile
              }
              ... on NewsArticle_Newsfcp_FlexibleContentNews_Spacer {
                hideDesktop
                hideMobile
                lineBreak
              }
              ... on NewsArticle_Newsfcp_FlexibleContentNews_Form {
                formId
                submissionLimiter
                replyTo
                adminNotifications {
                  emailAddress
                }
                thankYouMessage
                iWouldLikeToUseAThankYouPage
                thankYouPage {
                  ... on Post {
                    id
                    url: uri
                  }
                  ... on Page {
                    id
                    url: uri
                  }
                  ... on MediaItem {
                    id
                    mediaItemUrl: link
                  }
                  ... on AcfLink {
                    url
                  }
                }
              }
            }
          }
        }
      }`,
      variables: {
        slug,
        idType: preview ? 'DATABASE_ID' : 'URI',
        preview,
      },
      context,
    })

    return data
  }

  /**
   *
   * @param first
   * @param last
   * @param after
   * @param before
   * @param branchName
   * @param activeId
   * @param activeBranch
   * @returns
   */
  getPaginatedEvents = async (
    first: number | null,
    last: number | null,
    after: string | null,
    before: string | null,
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
  ) => {
    let context = {}

    if (this.accessToken) {
      context = {
        headers: {
          'x-authorization': `Bearer ${this.accessToken}`,
          'x-member-id': activeId ?? this.defaultId,
          'x-member-branch': activeBranch ?? this.defaultBranch,
          'x-member-location': activeLocation ?? this.defaultLocation,
        }
      }
    }

    const { data } = await wordpressClient.query<any>({
      query: gql`query getPaginatedEvents(
        $first: Int
        $last: Int
        $after: String
        $before: String
        $branchName: String
      ) {
      events: orderedEvents(first: $first, last: $last, after: $after, before: $before, where: {sdaBranch: $branchName}) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          total
        }
        edges{
          cursor
          node {
            date
            modified
            title
            uri
            eventId
            eventsFields {
              displayTitle
              eventDescription
              image {
                mediaItemUrl
              }
              sessions {
                venueTitle
                date
                time
                externalLinkUrl
                badgeText
                cancelled
              }
              eventStartDate
              eventEndDate
              eventType
              displayFromDate
              displayUntilDate
              isAMovie
              isFreeEvent
              isCancelled
              movieClassificationLevel
            }
          }
        }
      }
    }`,
      variables: {
        first,
        last,
        after,
        before,
        branchName: activeBranch ?? this.defaultBranch,
      },
      context,
    })

    return data
  }

  getSingleEvent = async (
    slug: string,
    preview = false,
    _preview_nonce?: string,
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
  ) => {
    let context: Record<string, any> = {}

    if (this.accessToken) {
      context = {
        headers: {
          'x-authorization': `Bearer ${this.accessToken}`,
          'x-member-id': activeId ?? this.defaultId,
          'x-member-branch': activeBranch ?? this.defaultBranch,
          'x-member-location': activeLocation ?? this.defaultLocation,
        }
      }
    }

    if (preview) {
      context.headers = {
        ...context.headers,
        'x-post-id': slug,
        'x-request-mode': 'preview',
        'x-preview-nonce': _preview_nonce ?? '',
      }
    }

    const { data } = await wordpressClient.query<any>({
      query: gql`query getSingleEvent($slug: ID!, $idType: EventIdType!, $preview: Boolean) {
        event(id: $slug, idType: $idType, asPreview: $preview) {
          date
          title
          uri
          eventsFields {
            displayTitle
            eventDescription
            image {
              mediaItemUrl
            }
            sessions {
              venueTitle
              date
              time
              externalLinkUrl
              badgeText
              cancelled
            }
            eventStartDate
            eventEndDate
            eventType
            displayFromDate
            displayUntilDate
            isAMovie
            isFreeEvent
            isCancelled
            movieClassificationLevel
          }
        }
      }`,
      variables: {
        slug,
        idType: preview ? 'DATABASE_ID' : 'URI',
        preview,
      },
      context,
    })

    return data
  }

  getSingleAward = async (
    postId: string,
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
    preview?: boolean,
  ) => {
    let context = {}

    if (this.accessToken) {
      context = {
        headers: {
          'x-authorization': `Bearer ${this.accessToken}`,
          'x-member-id': activeId ?? this.defaultId,
          'x-member-branch': activeBranch ?? this.defaultBranch,
          'x-member-location': activeLocation ?? this.defaultLocation,
        }
      }
    }

    const { data } = await wordpressClient.query<any>({
      query: gql`query Award($postId: ID!, $preview: Boolean) {
        award(id: $postId, idType: DATABASE_ID, asPreview: $preview) {
          id
          databaseId
          title
          awardCptFields {
            noAvailablePayrate
            awardThumbnail {
              mediaItemUrl
            }
            ebaTitle
            currentFrom
            bodySummaryText
            documentSummaryText
            ebaSummaryDocument {
              mediaItemUrl
            }
            faqContent {
              partTimeHours
              publicHolidays
              yourBreaks
              yourLeaveEntitlements
              yourPenaltyRates
              yourRoster
            }
            fullEbaDocument {
              mediaItemUrl
            }
            fullWageTable {
              mediaItemUrl
            }
            wageTablePdf {
              mediaItemUrl
            }
          }
        }
      }`,
      variables: {
        postId,
        preview,
      },
      context,
    })

    return data
  }

  getPaginatedCampaigns = async (
    first: number | null,
    last: number | null,
    after: string | null,
    before: string | null,
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
  ) => {
    let context = {}

    if (this.accessToken) {
      context = {
        headers: {
          'x-authorization': `Bearer ${this.accessToken}`,
          'x-member-id': activeId ?? this.defaultId,
          'x-member-branch': activeBranch ?? this.defaultBranch,
          'x-member-location': activeLocation ?? this.defaultLocation,
        }
      }
    }

    const { data } = await wordpressClient.query<any>({
      query: gql`query getPaginatedCampaigns(
        $first: Int
        $last: Int
        $after: String
        $before: String
        $branchName: String
      ) {
      campaigns(first: $first, last: $last, after: $after, before: $before, where: {sdaBranch: $branchName}) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          total
        }
        edges{
          cursor
          node {
            campaignId
            date
            modified
            title
            uri
            campaignsFcp {
              featuredImage {
                mediaItemUrl
              }
            }
          }
        }
      }
    }`,
      variables: {
        first,
        last,
        after,
        before,
        branchName: activeBranch ?? this.defaultBranch,
      },
      context,
    })

    return data
  }

  getSingleCampaignArticle = async (
    slug: string,
    preview = false,
    _preview_nonce?: string,
    activeId?: string,
    activeBranch?: string,
    activeLocation?: string,
  ) => {
    let context: Record<string, any> = {}

    if (this.accessToken) {
      context = {
        headers: {
          'x-authorization': `Bearer ${this.accessToken}`,
          'x-member-id': activeId ?? this.defaultId,
          'x-member-branch': activeBranch ?? this.defaultBranch,
          'x-member-location': activeLocation ?? this.defaultLocation,
        }
      }
    }

    if (preview) {
      context.headers = {
        ...context.headers,
        'x-post-id': slug,
        'x-request-mode': 'preview',
        'x-preview-nonce': _preview_nonce ?? '',
      }
    }


    const { data } = await wordpressClient.query<any>({
      query: gql`query getSingleCampaignArticle($slug: ID!, $idType: CampaignIdType!, $preview: Boolean) {
        campaign(id: $slug, idType: $idType, asPreview: $preview) {
          date
          title
          uri
          revisionOf {
            node {
              date
            }
          }
          campaignsFcp {
            featuredImage {
              mediaItemUrl
            }
            flexibleContentNews {
              __typename
              ... on Campaign_Campaignsfcp_FlexibleContentNews_CallToAction {
                buttonLinkInternal {
                  ... on AcfLink {
                    url
                  }
                  ... on Page {
                    id
                    url: uri
                  }
                  ... on MediaItem {
                    id
                    url: uri
                  }
                  ... on Event {
                    id
                    url: uri
                  }
                  ... on Award {
                    id
                    url: uri
                  }
                  ... on Benefit {
                    id
                    url: uri
                  }
                  ... on Notice {
                    id
                    url: uri
                  }
                  ... on NewsArticle {
                    id
                    url: uri
                  }
                  ... on Campaign {
                    id
                    url: uri
                  }
                  ... on SpecialPage {
                    id
                    url: uri
                  }
                }
                buttonLinkExternal
                buttonText
                subheading: callToActionText
                internal
                hideDesktop
                hideMobile
              }
              ... on Campaign_Campaignsfcp_FlexibleContentNews_Gallery {
                columns
                title
                images: gallery {
                  altText
                  mimeType
                  caption
                  title
                  description
                  imageThumbnail: sourceUrl(size: THUMBNAIL)
                  imageFull: mediaItemUrl
                }
                hideDesktop
                hideMobile
              }
              ... on Campaign_Campaignsfcp_FlexibleContentNews_Heading {
                centered
                heading
                headingType
                hideDesktop
                hideMobile
              }
              ... on Campaign_Campaignsfcp_FlexibleContentNews_OneColumnRow {
                html: column1
                hideDesktop
                hideMobile
              }
              ... on Campaign_Campaignsfcp_FlexibleContentNews_Spacer {
                hideDesktop
                hideMobile
                lineBreak
              }
              ... on Campaign_Campaignsfcp_FlexibleContentNews_Form {
                formId
                submissionLimiter
                replyTo
                adminNotifications {
                  emailAddress
                }
                thankYouMessage
                iWouldLikeToUseAThankYouPage
                thankYouPage {
                  ... on Post {
                    id
                    url: uri
                  }
                  ... on Page {
                    id
                    url: uri
                  }
                  ... on MediaItem {
                    id
                    mediaItemUrl: link
                  }
                  ... on AcfLink {
                    url
                  }
                }
              }
            }
          }
        }
      }`,
      variables: {
        slug,
        idType: preview ? 'DATABASE_ID' : 'URI',
        preview,
      },
      context,
    })

    return data
  }
}

export const getAuthedClient = (user: any) => {
  const activeBranch = getActiveBranchFromSession(user)
  const activeLocation = getActiveLocationFromSession(user)
  const activeId = user?.data?.activeId

  return activeBranch && activeId ? new AuthedClient(
    user.accessToken,
    activeId,
    activeBranch,
    activeLocation,
  ) : null
}
