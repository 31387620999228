import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import axios from 'axios'

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export const fetchEbas: any = createAsyncThunk('ebas/fetchEbas', async (params: { postIds: string[] }) => {
  const response: any = await axios.get('/api/auth/ebas', {params: {
    postIds: params.postIds
  }})
  return response?.data
})

interface IEbasData {
  status: Status
  lastError: string | null
  ebasData: any
}

const initialState: IEbasData = {
  status: Status.IDLE,
  lastError: null,
  ebasData: {}
}

export const ebas = createSlice({
  name: 'ebas',
  initialState: initialState,
  reducers: {
    resetEbas(state) {
      state = initialState
      state.status = Status.IDLE
    },
  },
  extraReducers: {
    [fetchEbas.pending.toString()]: (state) => {
      state.status = Status.LOADING
    },
    [fetchEbas.fulfilled.toString()]: (state, action) => {
      state.status = Status.SUCCEEDED
      state.ebasData = action?.payload?.ebas
    },
    [fetchEbas.rejected.toString()]: (state, action) => {
      state.status = Status.FAILED
      state.lastError = action.error.message

    },
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const {
  resetEbas,
} = ebas.actions

export default ebas.reducer
