import { getBackEndOptions, handleLogin, handlePasswordlessStart } from '@app/lib/loginUtils'
import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'axios'
import { HYDRATE } from 'next-redux-wrapper'
import { AppState } from '..'
import { ReactChild } from 'react'

export const propagateUserData = createAsyncThunk(
  'auth/propagateUserData',
  async (params: { userId: string, activeBranch: string, employerId: string }) => {
    const response = await axios.patch('/api/auth/updateUser', {
      branchId: params.activeBranch,
      userId: params.userId,
      employerId: params.employerId,
    })

    return response.data
  })

export const verifyMemberOnboarded = createAsyncThunk(
  'auth/verifyMemberOnboarded',
  async () => {
    const response = await axios.get('/api/auth/onboarded')

    return response.data
  })

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export type FlowType = 'mobile' | 'email' | 'initial'

export type MobileFlowStepType = 'phone' | 'code'

export type EmailFlowStepType = 'email' | 'code'

interface AuthState {
  status: Status
  flow: FlowType
  mobileFlowForm: {
    step: MobileFlowStepType
    phone: string
    code: string
  }
  emailFlowForm: {
    step: EmailFlowStepType
    email: string
    code: string
  }
  verified: boolean,
  onboarded: boolean,
  errorMessage: string | ReactChild,
}

const initialState: AuthState = {
  status: 'idle',
  storeEmployerStatus: 'idle',
  flow: 'initial',
  mobileFlowForm: {
    step: 'phone',
    phone: '',
    code: '',
  },
  emailFlowForm: {
    step: 'email',
    email: '',
    code: '',
  },
  verified: false,
  onboarded: true,
  errorMessage: '',
} as AuthState

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    flowUpdated(state, action) {
      state.flow = action.payload
      state.mobileFlowForm = initialState.mobileFlowForm
      state.emailFlowForm = initialState.emailFlowForm
    },
    mobileNumberUpdated(state, action) {
      state.mobileFlowForm = {
        ...state.mobileFlowForm,
        phone: action.payload,
      }
    },
    mobileFlowCodeUpdated(state, action) {
      state.mobileFlowForm = {
        ...state.mobileFlowForm,
        code: action.payload,
      }
    },
    mobileFlowStepUpdated(state, action) {
      state.mobileFlowForm = {
        ...state.mobileFlowForm,
        step: action.payload,
      }
    },
    emailUpdated(state, action) {
      state.emailFlowForm = {
        ...state.emailFlowForm,
        email: action.payload,
      }
    },
    emailFlowCodeUpdated(state, action) {
      state.emailFlowForm = {
        ...state.emailFlowForm,
        code: action.payload,
      }
    },
    emailFlowStepUpdated(state, action) {
      state.emailFlowForm = {
        ...state.emailFlowForm,
        step: action.payload,
      }
    },
    memberVerified(state) {
      state.verified = true
    },
    setLoadingStatus(state, action) {
      state.status = action.payload
    },
    setErrorMessage(state, action) {
      state.errorMessage = action.payload
    },
    setMemberOnboarded(state, action) {
      state.onboarded = action.payload
    },
    resetAuthStatus() {
      return initialState
    }
  },
  extraReducers: {
    [verifyMemberOnboarded.pending.toString()]: (state) => {
      state.status = Status.LOADING
    },
    [verifyMemberOnboarded.fulfilled.toString()]: (state, action) => {
      state.status = Status.SUCCEEDED
      state.onboarded = action.payload.onboarded
    },
    [verifyMemberOnboarded.rejected.toString()]: (state, action) => {
      state.status = Status.FAILED
      state.errorMessage = action.error.message

      console.log('error occurred. message: ')
      console.log(action.error.message)
    },
    [propagateUserData.pending.toString()]: (state) => {
      state.status = Status.LOADING
    },
    [propagateUserData.fulfilled.toString()]: (state) => {
      state.status = Status.SUCCEEDED
    },
    [propagateUserData.rejected.toString()]: (state, action) => {
      state.status = Status.FAILED
      state.errorMessage = action.error.message

      console.log('error occurred. message: ')
      console.log(action.error.message)
    },
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const {
  flowUpdated,
  mobileNumberUpdated,
  mobileFlowCodeUpdated,
  mobileFlowStepUpdated,
  emailUpdated,
  emailFlowCodeUpdated,
  emailFlowStepUpdated,
  memberVerified,
  setLoadingStatus,
  setErrorMessage,
  setMemberOnboarded,
  resetAuthStatus,
} = authSlice.actions

const _wrapNetworkError = (_error: Error) => 'The SDA members area is currently unavailable, please try again later'
const _wrapAccountBlockedError = (_error: Error) => '__ACCOUNT_BLOCKED_ERROR__'
// eslint-disable-next-line jsx-a11y/anchor-is-valid
const _wrapCodeOveruseError = (_error: Error) => '__CODE_OVERUSED_ERROR__'

export const login = () => async (dispatch: Dispatch, getState: AppState) => {
  const { auth: {
    flow,
    emailFlowForm,
    mobileFlowForm,
  } } = getState()

  const isEmailFlow = flow === 'email'

  try {
    dispatch(setLoadingStatus('loading'))
    dispatch(setErrorMessage(''))

    // Heartbeats
    await getBackEndOptions()

    const duplicatesCheck = await axios.post('/api/auth/checkDuplicates', {
      type: isEmailFlow ? 'email' : 'mobile',
      value: isEmailFlow ? emailFlowForm.email : mobileFlowForm.phone,
    })

    if (duplicatesCheck?.data?.hasDuplicates) {
      dispatch(isEmailFlow ? emailFlowStepUpdated('duplicate') : mobileFlowStepUpdated('duplicate'))
      dispatch(setLoadingStatus('idle'))
      return
    }

    await handlePasswordlessStart({
      email: isEmailFlow ? emailFlowForm.email : '',
      phoneNumber: isEmailFlow ? '' : mobileFlowForm.phone,
      loginType: isEmailFlow ? 'email' : 'sms'
    })

    dispatch(isEmailFlow ? emailFlowStepUpdated('code') : mobileFlowStepUpdated('code'))
    dispatch(setLoadingStatus('idle'))
  } catch (error: any) {
    if (error.message === 'Failed to fetch') error.message = _wrapNetworkError(error)

    if (error.response?.status === 406) {
      dispatch(isEmailFlow ? emailFlowStepUpdated('noauth') : mobileFlowStepUpdated('noauth'))
      dispatch(setLoadingStatus('idle'))
      return
    }

    dispatch(setLoadingStatus('failed'))
    dispatch(setErrorMessage(error?.response?.data?.message ?? error.message))
  }
}

export const validateCode = () => async (dispatch: Dispatch, getState: AppState) => {
    const { auth: {
      flow,
      emailFlowForm,
      mobileFlowForm,
    } } = getState()

  try {
    dispatch(setLoadingStatus('loading'))
    dispatch(setErrorMessage(''))

    // Heartbeats
    await getBackEndOptions()

    const isEmailFlow = flow === 'email'

    await handleLogin({
      email: isEmailFlow ? emailFlowForm.email : '',
      phoneNumber: isEmailFlow ? '' : mobileFlowForm.phone,
      connection: isEmailFlow ? 'email' : 'sms',
      send: 'code',
      verificationCode: isEmailFlow ? emailFlowForm.code : mobileFlowForm.code,
    })

    dispatch(memberVerified())
    dispatch(setLoadingStatus('idle'))
  } catch (error: any) {
    const description = error?.response?.data?.error_description

    dispatch(setLoadingStatus('failed'))
    if (error.message === 'Failed to fetch') return dispatch(setErrorMessage(_wrapNetworkError(error)))
    if (description?.toLowerCase().includes('your account has been blocked')) return dispatch(setErrorMessage(_wrapAccountBlockedError(error)))
    if (description?.toLowerCase().includes('maximum number of attempts')) return dispatch(setErrorMessage(_wrapCodeOveruseError(error)))

    dispatch(setErrorMessage(description ?? error.message))
  }
}

export const saveMemberOnboarded = () => async (dispatch: Dispatch) => {
  await axios.patch('/api/auth/onboarded')
  dispatch(setMemberOnboarded(true))
}

export default authSlice.reducer

