import axios from 'axios'
import parsePhoneNumber from 'libphonenumber-js'
import { DATA_WAREHOUSE_GRAPHQL_HOST, WORDPRESS_GRAPHQL_URL } from '@app/lib/urls'

export type loginType = 'sms' | 'email'

export type loginFormPayload = {
  email?: string
  phoneNumber?: string
  loginType: loginType
}

export type authPayload = {
  email?: string
  phoneNumber?: string
  connection: string
  send: 'code' | 'link'
  verificationCode: string
}

export const formatPhoneNumber = (phoneNumber: string) => String(parsePhoneNumber(phoneNumber, 'AU')?.number)

export const handlePasswordlessStart = async (payload: loginFormPayload) => {
  if (payload.phoneNumber && payload.phoneNumber.length > 0) {
    payload.phoneNumber = formatPhoneNumber(payload.phoneNumber)
  }

  return axios.post('/api/auth/start', payload)
}

export const handleLogin = async (payload: authPayload) => {
  if (payload.phoneNumber && payload.phoneNumber.length > 0) {
    payload.phoneNumber = formatPhoneNumber(payload.phoneNumber)
  }

  return axios.post('/api/auth/login', payload)
}

export const getBackEndOptions = async () => {
  const options: RequestInit = { method: 'GET', mode: 'no-cors' }

  return Promise.all([
    fetch(WORDPRESS_GRAPHQL_URL, options),
    fetch(`${DATA_WAREHOUSE_GRAPHQL_HOST}/.well-known/apollo/server-health`, options),
  ])
}
